//Code
const ContentActionTypes = {
  FETCH_CONTENT_START: 'FETCH_CONTENT_START',
  FETCH_CONTENT_SUCCESS: 'FETCH_CONTENT_SUCCESS',
  FETCH_CONTENT_FAILURE: 'FETCH_CONTENT_FAILURE',
  FETCH_ARTICLE_START: 'FETCH_ARTICLE_START',
  FETCH_ARTICLE_SUCCESS: 'FETCH_ARTICLE_SUCCESS',
  FETCH_ARTICLE_FAILURE: 'FETCH_ARTICLE_FAILURE',
};

export default ContentActionTypes;
