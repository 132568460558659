//Framework
import React from 'react';
//Libraries
import Tippy from "@tippyjs/react";
//Assets
import spriteTech from "assets/spriteSVG/spriteTech.svg";
//External CSS
// import "tippy.js/dist/tippy.css";
// import "tippy.js/themes/material.css";
// import "tippy.js/animations/scale-subtle.css";
//Design
import {Icon, FeatureContainer, Feature} from './flexIcons.styles';
//Types
import {frontEnd, frontEndNames, backEnd, backEndNames} from './flexicons.types';

//Code
export const FrontEnd = (props) => {

  return (
    <FeatureContainer>
      {frontEnd.map((tab, i) => (
        <Feature>
          <Tippy
            content={frontEndNames[i]}
            placement="top"
            animation="scale-subtle"
            arrow={true}
            duration={200}
            delay={[75, 0]}
            distance={8}
            className="tippy-icons-theme"
          >
            <Icon>
              <use href={spriteTech + `#${tab}`} />
            </Icon>
          </Tippy>
        </Feature>
      ))}
    </FeatureContainer>
  );
};



export const BackEnd = (props) => {

  return (
    <FeatureContainer>
      {backEnd.map((tab, i) => (
        <Feature>
          <Tippy
            content={backEndNames[i]}
            placement="top"
            animation="scale-subtle"
            arrow={true}
            duration={200}
            delay={[75, 0]}
            distance={8}
            className="tippy-icons-theme"
          >
            <Icon>
              <use href={spriteTech + `#${tab}`} />
            </Icon>
          </Tippy>
        </Feature>
      ))}
    </FeatureContainer>
  );
};

export default FrontEnd;


