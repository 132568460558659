//Framework
import React from 'react';
//Styles
import {
  SpinnerOverlay,
  TriangleSpinner,
  Message
} from "./fallback-custom-spinner.styles";

const CustomSpinner = () => (
  <SpinnerOverlay>
    <div style={{display: 'flex', flexDirection: 'column', margin: 0, padding: 0}} >
    <TriangleSpinner />
    <Message >Waiting . . .</Message>
    </div>
  </SpinnerOverlay>
);

export default CustomSpinner;
