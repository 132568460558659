//Code
const MessageMeActionTypes = {
  SEND_DATA_SERVER_START: "SEND_DATA_SERVER_START",
  SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER:
    "SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER",
  SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER_SUCCESS:
    "SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER_SUCCESS",
  SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER_FAILURE:
    "SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER_FAILURE",
  CUSTOMER_COUNTRY: "CUSTOMER_COUNTRY",
  RICH_TEXT_MESSAGE_AS_HTML: "RICH_TEXT_MESSAGE_AS_HTML",
  CALL_TO_API_SUCCESS: "CALL_TO_API_SUCCESS",
  CALL_TO_API_FAILURE: "CALL_TO_API_FAILURE",
  UPDATE_IS_LOADING: "UPDATE_IS_LOADING",
  TOGGLE_IS_COMPLETE: "TOGGLE_IS_COMPLETE",
};

export default MessageMeActionTypes;
