//Framework
import React, { useState } from 'react';
//Libraries
import Lottie from "react-lottie-light";
//Design
import {MiniCardsContainer, FlexContainer, MiniCardContainer, MiniCard, MiniCard2, ArrowContainer, LinkLottie} from './more-options.styles'
//Assets
import arrowRight from 'assets/Lottie/arrow--right--orange.json'


export const MiniCards = ({linkToBig, linkToSmall, subjectBig, subjectSmall, ctaBig, ctaSmall, customTarget}) => {

  let [isPaused, setIsPaused] = useState(false);
  let [isStopped, setIsStopped] = useState(false);
  
  const defaultOptions = {
        loop: true,
        autoplay: true,
        hover: true,
        animationData: arrowRight,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
          progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
          hideOnTransparent: true,
          className: "lottie-animation-library",
          id: "returnToVideo",
        },
  };
      
  const renderSwitchTextBig = (param) => {
    switch (param) {
      case "developer":
        return (
          <>
            <h3 className="heading-tertiary ">
              Are you hungry for a modern developer?
            </h3>
            <span />
            <p className="feature-box__text">
              You can find a{" "}
              <div
                style={{
                  display: "inline",
                  fontWeight: 400,
                  fontSize: "inherit",
                  fontFamily: "inherit",
                }}
              >
                Vegan
              </div>{" "}
              option here.
            </p>
          </>
        );
      case "article--email":
        return (
          <>
            <h3 className="heading-tertiary ">
              Send emails with Node.js and React
            </h3>
            <span />
            <p className="feature-box__text">
              In my compact {" "}
              <div
                style={{
                  display: "inline",
                  fontWeight: 400,
                  fontSize: "inherit",
                  fontFamily: "inherit",
                }}
              >
               article
              </div>{" "}
              about it.
            </p>
          </>
        );
      default:
        return (
          <>
            <span style={{ fontWeight: 200 }}>it's</span>{" "}
            <span style={{ fontWeight: 400, color: "#EB5E28" }}>Aaaa</span>
            <span style={{ fontWeight: 200 }}>mazing</span>
          </>
        );
    }
  };
  const renderSwitchTextSmall = (param) => {
    switch (param) {
      case "linkedin":
        return (
          <>
            <h3 className="heading-tertiary ">Need more?</h3>
            <span />
            <p className="feature-box__text">
              Explore my{" "}
              <div
                style={{
                  display: "inline",
                  fontWeight: 400,
                  fontSize: "inherit",
                  fontFamily: "inherit",
                }}
              >
                Linkedin
              </div>
              .
            </p>
          </>
        );
      case "article--advanced-mixins-part2":
        return (
          <>
            <h3 className="heading-tertiary ">Start <br/> Styled Components</h3>
            <span />
            <p className="feature-box__text">
              way easier than{" "}
              <div
                style={{
                  display: "inline",
                  fontWeight: 400,
                  fontSize: "inherit",
                  fontFamily: "inherit",
                }}
              >
                SASS
              </div>
            </p>
          </>
        );
      default:
        return (
          <>
            <span style={{ fontWeight: 200 }}>it's</span>{" "}
            <span style={{ fontWeight: 400, color: "#EB5E28" }}>Aaaa</span>
            <span style={{ fontWeight: 200 }}>mazing</span>
          </>
        );
    }
  };

        return (
          <MiniCardsContainer className="miniCardsContainer">
            <FlexContainer>
              <MiniCardContainer>
                <MiniCard>
                  {renderSwitchTextBig(subjectBig)}
                  <a href={linkToBig} className="link-read-more">
                    <ArrowContainer>
                      {ctaBig}
                      <LinkLottie to={linkToBig}>
                        <Lottie
                          className="lottieBlob"
                          options={defaultOptions}
                          height={"auto"}
                          width={"100%"}
                          isStopped={isStopped}
                          isPaused={isPaused}
                          style={{
                            borderRadius: "inherit",
                            alignSelf: "center",
                          }}
                        />
                      </LinkLottie>
                    </ArrowContainer>
                  </a>
                  {/* </Link> */}
                </MiniCard>
              </MiniCardContainer>
              <MiniCardContainer>
                <MiniCard2>
                  {renderSwitchTextSmall(subjectSmall)}
                  <a
                    rel="nofollow noopener"
                    href={linkToSmall}
                    target={customTarget ? customTarget : "_self"}
                    className="link-read-more"
                  >
                    <ArrowContainer>
                      {ctaSmall}
                      <LinkLottie
                        rel="nofollow noopener"
                        as="a"
                        href={linkToSmall}
                      >
                        <Lottie
                          className="lottieBlob"
                          options={defaultOptions}
                          height={"auto"}
                          width={"100%"}
                          isStopped={isStopped}
                          isPaused={isPaused}
                          style={{
                            borderRadius: "inherit",
                            alignSelf: "center",
                          }}
                        />
                      </LinkLottie>
                    </ArrowContainer>
                  </a>
                </MiniCard2>
              </MiniCardContainer>
            </FlexContainer>
          </MiniCardsContainer>
        );
    }


export default MiniCards;