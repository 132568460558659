import React, { Component } from 'react';
import {
  Arrow,
  ArrowContainer,
  ScrollDownCTA,
} from "./scroll-down.styles";

//Code
class ScrollDown extends Component {
    render() {

        return (
          <ScrollDownCTA>
            <ArrowContainer
              activeClass="active"
              to="youtube-player-tag2"
              spy={true}
              smooth={true}
              offset={-300}
              duration={1000}
            >
              <Arrow>
                <path
                  stroke-linecap="round"
                  class="a1"
                  d="M0 0 L20 22 L40 0"
                ></path>
              </Arrow>
            </ArrowContainer>
          </ScrollDownCTA>
        );
    }
}

export default (ScrollDown);