//Libraries
import styled from "styled-components";
//Responsive
import respond from 'design/responsive';

//Code
export const HeroHeaderRight = styled.div`
  grid-column: center-start / center-end;
  grid-row: 4 / span 1;
  height: 50vh;
  height: min-content;
  height: minmax(50vh, min-content);
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  overflow: hidden !important;

  & > * {
    justify-self: flex-start;
    align-self: flex-start;
    overflow: visible !important ;
  }
  svg {
    overflow: visible !important;
  }

  margin-top: 9%;
  ${respond.pc.standard`
    grid-row: 2 / span 1;
    margin-top: 0%;
    ${'' /* grid-column: col-start 5 / col-end 7; */}
    grid-column: col-start 5 / center-end;
    
    height: min-content;
    height: auto;
    padding-bottom: 4vw;   

  `}
`;

export const LottieContainer = styled.div`
  place-self: center;
  height: 50vw;
  height: auto;
  height: 100%;
  width: 65%;
  position: relative;
  display: grid;
  place-items: center;
  border-radius: 50%;
  border: 200px red;
  border-radius: 70% 30% 30% 70% / 60% 40% 60% 40%;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  ${"" /* Remove translate3d bug over Lottie animation using persperctive  */}
  transform: perspective(20px);

  svg {
    align-self: center;
    justify-self: center;
    transform: "none" !important;
  }
  ${respond.tablet.standard`
     height: 100%;
     width: 65%;
  `}

  ${respond.pc.standard`
     width: 82%;
     height: 100%;
     align-items: center;
     align-content:center;
  `}
`;


