import ContentActionTypes from './content.types';

const INITIAL_STATE = {
  data: null, // Main entry for Headless-CMS articles to our app.
  isFetching: false,
  isFetchingSingle: false,
  errorMessage: undefined,
  post: null,
  previousPost: null,
};

const contentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContentActionTypes.FETCH_CONTENT_START:
      return {
        ...state,
        isFetching: true,
        hasData: !!state.data,
      };
    case ContentActionTypes.FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case ContentActionTypes.FETCH_CONTENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.payload,
      };
    case ContentActionTypes.FETCH_ARTICLE_START:
      return {
          ...state,
          isFetchingSingle: true,
          previousPost: state.post,
          post: null,
        };
    // case ContentActionTypes.FETCH_ARTICLE_START:
    //   const posts = state.data.items;
    //     const finalPost = posts.filter((post) => post.slug === action.payload);
    //     return  {
    //       ...state,
    //       post: finalPost,
    //     }
    case ContentActionTypes.FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        isFetchingSingle: false,
        post: action.payload,
      };
    case ContentActionTypes.FETCH_ARTICLE_FAILURE:
      return {
        ...state,
        isFetchingSingle: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default contentReducer;
