//Framework
import React from 'react';
//Design
import {  SpinnerOverlay , ImageContainer, TriangleSpinner} from './fallback-spinner-component.styles';

//Code
const SpinnerComponent = (props) => {
  return (
    <SpinnerOverlay>
      <ImageContainer>
        <TriangleSpinner />
      </ImageContainer>
    </SpinnerOverlay>
  );
}

export default SpinnerComponent;
