//Libraries
import styled from 'styled-components';
//Design
import colors from 'design/colors';
//Responsive
import respond from 'design/responsive';
//Containers
import {VegeloperContainer} from 'design/containers';

export const SpinnerOverlay = styled(VegeloperContainer)`
  grid-column: cover-start / cover-end;
  background: transparent;
  position: relative;
  height: 70vw;
  display: grid;
  justify-content: center;
  align-content: center;
  background: transparent;
`;

export const ImageContainer = styled.div`
  grid-column: full-start / full-end;
  grid-column: full-start / full-end;
  position: relative;
  background: white;
  margin-right: 1rem;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 70vw;

  border-radius: 4vw;
  overflow: hidden;

  ${respond.mobile.landscape`
   height: 60vw;
  `}

  ${respond.tablet.standard`
    height: 60vw;
     grid-column: center-start / center-end;
  `}
  ${respond.pc.standard`
    height: 50vw;
    grid-column: center-start / center-end;
  `}
`;

export const TriangleSpinner = styled.div`
  display: inline-block;

  width: 2vw;
  height: 2vw;
  box-sizing: content-box;
  border-color: blue;
  background-color: ${colors.accent.lightest};
  background: linear-gradient(
    100deg,
    ${colors.accent.darkest} 0%,
    ${colors.accent.lightest} 35%
  );

  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: spin 2s ease-in-out infinite;
  -webkit-animation: spin 2s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;