//Framework
import React , {useEffect, useState} from 'react';
//Styles
import {
  NavContainer,
  Nav,
  NavButton,
  NavIcon,
  NavText,
  NavBurger,
  StyledLink,
} from "./modern-nav.styles";

export const ModernNav = ({open, toggleMenuOpen}) => {

  const [isShown, setIsShown] = useState(false);
  
  //Implement Delay in changing 'open' props upon closing the burgerMenu.
  const waitBeforeShow = 2000;
   useEffect(() => {
     if (open && !isShown) {
         setIsShown(true);
     }
     if (open && isShown) {
        // Do Nothing
     }
     if (!open && isShown) {
        var timer = setTimeout(() => {
          setIsShown(false);
        }, waitBeforeShow);
     }
     if (!open && !isShown) {
         // Do Nothing
     }
     return () => clearTimeout(timer);
 
   }, [open]);


    return (
      <>
        <NavContainer isShown={isShown} open={open}>
          <Nav>
            <StyledLink to="/">
              <NavButton>
                <NavIcon className="material-icons uil uil-estate">home</NavIcon>
              </NavButton>
              <NavText>Home</NavText>
            </StyledLink>
            <StyledLink to="/myposts">
              <NavButton>
                <NavIcon className="material-icons">code</NavIcon>
              </NavButton>
              <NavText>Articles</NavText>
            </StyledLink>
            <StyledLink to="/hipal">
              <NavButton>
                <NavIcon className="material-icons uil uil-message">
                  email
                </NavIcon>
              </NavButton>
              <NavText>Message</NavText>
            </StyledLink>
          </Nav>
        </NavContainer>
          <NavBurger className="nav-burger" for="check" open={open}>
            <input type="checkbox" id="check" onClick={toggleMenuOpen} />
            <span></span> {/* Line #1 */}
            <span></span> {/* Line #2 */}
            <span></span> {/* Line #3 */}
          </NavBurger>
      </>
    );
};

