//Libraries
import styled from "styled-components";
//Responsive
import respond from 'design/responsive';

export const FeatureContainer = styled.section`
  width: max-content;
  position: relative;
  bottom: 0;
  margin: 0rem 0;
  margin-top: 2rem;
  height: min-content;
  border-bottom: 0.5vw solid #eb5e28;
  display: inline-flex; // flex lets it to have all items in a single row
  flex-direction: row;
  flex-wrap: nowrap; // lets the flex to occupy new row when space is not enough
  justify-content: center; // Horizontally align items inside the flex container
  align-items: center; // Vertically align items inside the flex container

  ${respond.pc.standard`
  justify-content: flex-end; // Horizontally align items inside the flex container
  `}
`;

export const Feature = styled.div`
  display: flex;
  flex-direction: column; // changing the direction to vertical
  align-items: center; // centering icons in horizontal
  justify-content: center; // centering icons in vertical
  color: #102539;

  // defining the size and property of each flex-cell
  width: min-content;
  height: min-content;

  background-color: rgba(182, 125, 144, 0.025);
  border-radius: 50%;
 
  margin-right: .15rem;
  margin-left: .15rem;

  &:hover {
    color: #003153;
  }
`;

export const Icon = styled.svg`
  display: grid;
  place-items: center;
  margin: 0 auto;
  width: 6.6666vw;
  height: 6.6666vw;
  fill: currentColor;

  ${respond.pc.standard`
    width: 2vw;
  height: 2vw;
  `}
`;
