//Libraries
import { Link } from "react-router-dom";
import styled, {css} from "styled-components";
//Responsive
import { respond } from "design/responsive";
//Design
import colors from "design/colors";
import shadows from "design/shadows";
import fonts from 'design/fonts';
//Assets
import { ReactComponent as VegeloperLogo } from "assets/SVG/Vegeloper.svg";
import { ReactComponent as WebWeaverLine } from "assets/SVG/WebWeaver-Line.svg";


//Code

export const HigherContainer = styled.div`
  grid-column: cover-start / cover-end;
  grid-row: 1 / span 1;

  display: none;
  grid-template-columns: [cover-start leftSide-start] minmax(1vw,1fr) [leftSide-end full-start] 9vw [center-start] repeat(8,[col-start] minmax(min-content,1fr) [col-end]) [center-end] 9vw [full-end rightSide-start] minmax(1vw,1fr) [rightSide-end cover-end];
  grid-template-columns: [cover-start leftSide-start] minmax(1vw,1fr) [leftSide-end full-start] 9vw [center-start] repeat(8,[col-start] 1.15fr [col-end]) [center-end] 9vw [full-end rightSide-start] minmax(1vw,1fr) [rightSide-end cover-end];


  width: 100%;
  z-index: 10000000;
 
  background-color: transparent;
  top: 0;
  left: 0;

  ${respond.pc.standard`
  display: grid;
  `}

`;

export const HeaderContainer = styled.div`
  grid-column: center-start / center-end;
  grid-row: 1 / span 1;
  z-index: 10000001;

  top: 0;
  left: 0;
  display: flex;

  align-items: center;
  justify-content: space-between;

  background-color: rgb(242, 243, 247);
  background-color: transparent;
  transform: scale(0.1);

  //Hiding menu before reaching tablet viewport.
  height: 0vh;
  opacity: 0;

  

  ${respond.pc.min`
     
    grid-column: center-start / center-end;
       height: clamp(96px, 9.6vw, 9.6vw);
    transform: scale(1);
    opacity: 1;
  `};
`;

export const LogoContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LogoSVG = styled(VegeloperLogo)`
  width: clamp(7rem, 11.6666vw, 11.6666vw);
  height: clamp(7rem, 8.75vh, 11.6666vw);
  //Fix SVG to left side.
  padding-right: clamp(12px, 1.2vw, 1.2vw);

  ${respond.pc.standard`
    width: clamp(7rem, 6.8359375vw, 6.8359375vw);
    height: clamp(7rem, 6.8359375vw, 6.8359375vw);
  `}
`;

export const LogoText = styled.p`
  ${fonts.menu.logo};

  color: ${colors.primary};
  margin-left: clamp(7px, 0.7vw, 0.7vw);

  @media screen and (max-width: 650px) {
    display: none;
    aria: hidden;
  }
`;

export const LogoLine = styled(WebWeaverLine)`
  width: 1.5rem;
  width: clamp(1.5rem, 2.5vw, 2.5vw);
  height: 3rem;
  height: clamp(3rem, 5vw, 5vw);
  fill: ${colors.primary};

  margin-top: .5rem;

  @media screen and (max-width: 650px) {
    display: none;
    aria: hidden;
  }
`;

export const MenuButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;

  & > * {
    &::before,
    &::after {
      box-sizing: inherit;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
`;

export const PrussianBlueToOrange = styled.span`
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  transition: inherit;
`;

const activeStateHandler = css`
  transition: all 0.4s;
  box-shadow: ${shadows.neumorphic.orange} !important;
  background-color: #fff !important;

  &:hover {
    box-shadow: ${shadows.neumorphic.orange2} !important;
  }

  &:active {
    box-shadow: ${shadows.neumorphic.originalOrange} !important;
  }
`;

const nestedStateHandler = css`
  transition: all 0.4s;
  box-shadow: ${shadows.neumorphic.orange} !important;
  background-color: #fff !important;

  &:hover {
    box-shadow: ${shadows.neumorphic.orange2} !important;
  }

  &:active {
    box-shadow: ${shadows.neumorphic.originalOrange} !important;
  }
  &::after {
    opacity: 1;
    position: absolute;
    content: "";
    top: 120%;
    left: 50%;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    border-radius: 50%;
    width: 0.5vw;
    height: 0.5vw;
    background: ${colors.accent.ochreFire};
    background: ${colors.accent.lightest};
    transition: opacity 15s !important;
    transform: translate(-50%, 0%);
  }
`;

export const Icon = styled.i`
  transition: all 0.4s;
  font-size: 120%;
`;

export const MenuButton = styled(Link)`
  ${fonts.menu.link};

  ${shadows.mixins.neumorphic.button};
  ${shadows.mixins.neumorphic.original};

  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.primary};

  height: clamp(4.2rem, 7vw, 7vw);
  width: clamp(7rem, 11.66666vw, 11.66666vw);

  cursor: pointer;

  transition: background-color 0.3s ease;
  transition: all 0.4s;
  border-radius: clamp(15px, 1.5vw, 1.5vw);
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  position: relative;
  vertical-align: middle;

  ${respond.pc.standard`
    height: 3.5vw;
    height: clamp(3.5rem,  3.5vw, 3.5vw);
    width: clamp(6.5rem, 6.8359375vw, 6.8359375vw);
  `}

  &:hover {
    background-color: white;
     box-shadow: .4rem .4rem 1rem 0rem ${colors.shadows.dark},
      -.4rem -.4rem 1rem 0rem ${colors.highlights.lightest};
  }

  &:active {
    transform: translateY(2px);
    transition: transform 0.15s ease, box-shadow 0.1s linear;
    box-shadow: ${shadows.neumorphic.original};
  }

  :not(:last-of-type) {
    margin-right: clamp(5px, 1.2vw, 1.2vw);
    margin-right: clamp(5px, 1.66666vw, 1.66666vw);
  }

  &:hover ${PrussianBlueToOrange} {
    color: ${colors.accent.lightest};
  }

  &:hover ${Icon} {
    color: ${colors.accent.lightest};
  }

  &::before {
    bottom: 0;
    right: 0;
  }

  &::after {
    top: 0;
    left: 0;
  }
  &::before,
  &::after {
    border-radius: 1px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 0;
    height: 0;
  }
  &::after {
    opacity: 0;
    position: absolute;
    content: "";
    top: 120%;
    left: 50%;
    border-bottom-left-radius: 40%;
    border-bottom-right-radius: 40%;
    border-radius: 50%;
    width: 0.5vw;
    height: 0.5vw;
    background: ${colors.accent.ochreFire};
    background: ${colors.accent.lightest};
    transition: all 15s !important;
    transform: translate(-50%, 0%);
  }

  ${(p) => p.pageActive === p.pageNumber && activeStateHandler};
  ${(p) => p.pageActive === "5" && p.pageNumber === "4" && nestedStateHandler};
`;

export const MenuButtonOrange = styled(MenuButton)`
  box-shadow: ${shadows.neumorphic.orange} !important;
  background-color: #fff !important;

  &:hover {
    box-shadow: ${shadows.neumorphic.orange2} !important;
  }

  &:active {
    box-shadow: ${shadows.neumorphic.originalOrange} !important;
  }

`;

export const MenuButtonIcon = styled(MenuButton)`
  width: clamp(3rem, 4vw, 4vw) !important;
`;

MenuButton.displayName = "OptionLink";


