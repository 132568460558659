
export const colors = {
  shadows: {
    lightest: "rgba(125, 126, 127, 0.8)",
    lighter: " rgba(125, 126, 127, 0.6)",
    light: "rgba(125, 126, 127, 0.4)",
    dark: "rgba(125, 126, 127, 0.3)",
  },

  highlights: {
    lightest: "rgba(255, 255, 255, 0.9)",
    lighter: " rgba(255, 255, 255, 0.8)",
    light: "rgba(255, 255, 255, 0.7)",
    dark: "rgba(255, 255, 255, 0.5)",
  },

  CTA: {
    lightest: "rgba(125,126,127,0.2)",
    lighter: " rgba(125,126,127,0.4)",
    light: "grey",
    dark: "darkgrey",
  },
  neutrals: {
    lightest: "#FFFFFF",
    lighter: "#F9F9FA",
    light: "#F0F0F3",
    darkest: "#102539",
  },
  secondary: {
    lightest: "#D1D9E6",
    lighter: "#284F70",
    darker: "#003153",
    darkest: "#143351",
  },
  accent: {
    lightest: "#EB5E28",
    darkest: "#D65522",
    new1: "#fd6a0f",
    new2: "#fde10f",
    new3: "#2F4858",
    ochreFire: "#FDA50F",
    ochrePale: "#EB9928",
    ochreOxide: "#C67F39",
  },
  white: "rgb(248, 248, 249)",
  black: "#102539",
  primary: "#003153",
  success: '#8bc34a',
  alert: '#D65522',
  palePink: "#fde2e4",
  paleYellow: "#fff1e6",
  paleGreen: " #dbe7e4",
  paleBlue: "#d6e2e9",
  grayLighter: "#D8D8D8",
  grayLight: "#ccc",
  grayDark: "#444",
  grayDarker: "#222",
  blueLight: "rgb(33, 150, 243)",
  blueDark: "rgb(16, 73, 119)",
  greenLight: "#8bc34a",
  greenDark: "#3b5221",
  yellowLight: "#ffc107",
  yellowDark: "#715605",
  redLight: "rgba(220, 30, 0, 0.912)",
  redDark: "rgba(120, 30, 0, 0.912)",
};




export default colors;

