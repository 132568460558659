//Framework
import React , {useState} from "react";
import {Link} from 'react-router-dom';
//Libraries
import Tippy from "@tippyjs/react";
import Lottie from "react-lottie-light";
import { Waypoint } from "react-waypoint";
//Design
import {
  Box,
  Copyright,
  UpperContainer,  
  Container,
  Row,
  ColumnLeft,
  ColumnRight,
  FooterReactLink,
  Heading,
  LottieLink,
  InnerColumnIcons,
  InnerColumnLinks,
  SvgLogo,
  Chunk,
  CopyrightContainer
} from "./footer.styles";
//Assets
import AnimatedMedium from 'assets/Lottie/medium--primary.json';
import AnimatedGithub from 'assets/Lottie/animated--github--mono.json';
import AnimatedLinkedin from 'assets/Lottie/newLinkedin.json';
import AnimatedEmail from 'assets/Lottie/email.json';

//Code
const Footer = () => {
    let [isPaused, setIsPaused] = useState(true);
    let [isStopped, setIsStopped] = useState(true);
    let [loopNumber, setLoopNumber] = useState(3);

    const optionsLinkedin = {
      loop: 5,
      autoplay: true,
      hover: true,
      animationData: AnimatedLinkedin,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        progressiveLoad: true,
        hideOnTransparent: true,
        className: "lottie-animation-library",
        id: "returnToVideo",
      },
    };
    const optionsGithub = {
      loop: 12,
      autoplay: true,
      hover: true,
      animationData: AnimatedGithub,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        progressiveLoad: true,
        hideOnTransparent: true,
        className: "lottie-animation-library",
        id: "returnToVideo",
      },
    };
    const optionsEmail = {
      loop: 8,
      autoplay: true,
      hover: true,
      animationData: AnimatedEmail,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        progressiveLoad: true,
        hideOnTransparent: true,
        className: "lottie-animation-library",
        id: "returnToVideo",
      },
    };
    const optionsMedium = {
      loop: loopNumber,
      autoplay: true,
      hover: true,
      animationData: AnimatedMedium,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
        progressiveLoad: true,
        hideOnTransparent: true,
        className: "lottie-animation-library",
        id: "returnToVideo",
      },
    };
  return (
    <Box>
      <UpperContainer style={{ textAlign: "center" }}>
        <Link to="/">
          <SvgLogo alt="vegeloper's logo" />
        </Link>
        <CopyrightContainer>
          <Copyright>
            <span style={{ fontWeight: "400" }}>Vegeloper.com</span> Copyright
            2021
          </Copyright>
        </CopyrightContainer>
      </UpperContainer>
      <Container>
        <Row>
          <Chunk>
            <ColumnLeft>
              <Heading>Social</Heading>
              <InnerColumnIcons className="innerColumn--icons">
                <Waypoint
                  onEnter={() =>
                    setTimeout(() => {
                      setIsPaused(false);
                      setIsStopped(false);
                    }, 10)
                  }
                  onLeave={() =>
                    setTimeout(() => {
                      setIsPaused(true);
                      setIsStopped(true);
                    }, 10)
                  }
                  topOffset="70%"
                  bottomOffset="-10%"
                  scrollableAncestor={window}
                />
                <Tippy
                  content={"GitHub"}
                  placement="right"
                  animation="scale-subtle"
                  arrow={true}
                  duration={200}
                  delay={[75, 0]}
                  distance={8}
                  className="tippy-icons-theme"
                >
                  <LottieLink
                    href="https://github.com/vegeloper"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    <Lottie
                      className="lottieBlob"
                      options={optionsGithub}
                      height={"100%"}
                      width={"100%"}
                      isStopped={isStopped}
                      isPaused={isPaused}
                      style={{
                        borderRadius: "inherit",
                        alignSelf: "center",
                        flex: "80%",
                        margin: "0",
                      }}
                    />
                  </LottieLink>
                </Tippy>

                <Tippy
                  content={"Email"}
                  placement="right"
                  animation="scale-subtle"
                  arrow={true}
                  duration={200}
                  delay={[75, 0]}
                  distance={8}
                  className="tippy-icons-theme"
                >
                  <LottieLink
                    href="mailto:khashi@vegeloper.com"
                    target="_blank"
                    rel="nofollow"
                  >
                    <Lottie
                      className="emailIcon"
                      options={optionsEmail}
                      height={"100%"}
                      width={"100%"}
                      isStopped={isStopped}
                      isPaused={isPaused}
                      style={{
                        borderRadius: "inherit",
                        alignSelf: "center",
                        flex: "80%",
                        margin: "0",
                        transform: "translateX(-5%)",
                      }}
                    />
                  </LottieLink>
                </Tippy>
                <Tippy
                  content={"Linkedin"}
                  placement="right"
                  animation="scale-subtle"
                  arrow={true}
                  duration={200}
                  delay={[75, 0]}
                  distance={8}
                  className="tippy-icons-theme"
                >
                  <LottieLink
                    rel="nofollow noopener"
                    href="https://www.linkedin.com/in/khashi-akhavan/"
                    target="_blank"
                  >
                    <Lottie
                      className="lottieBlob"
                      options={optionsLinkedin}
                      height={"100%"}
                      width={"100%"}
                      isStopped={isStopped}
                      isPaused={isPaused}
                      style={{
                        borderRadius: "inherit",
                        alignSelf: "center",
                        flex: "80%",
                        margin: "0",
                      }}
                    />
                  </LottieLink>
                </Tippy>
                <Tippy
                  content={"Medium"}
                  placement="right"
                  animation="scale-subtle"
                  arrow={true}
                  duration={200}
                  delay={[75, 0]}
                  distance={8}
                  className="tippy-icons-theme"
                >
                  <LottieLink
                    href="https://vegeloper.medium.com"
                    target="_blank"
                    rel="nofollow noopener"
                    style={{ borderRadius: "50%;" }}
                  >
                    <Lottie
                      className="lottieBlob"
                      options={optionsMedium}
                      height={"100%"}
                      width={"100%"}
                      isStopped={isStopped}
                      isPaused={isPaused}
                      style={{
                        borderRadius: "50%",
                        alignSelf: "center",
                        flex: "80%",
                        margin: "0",
                      }}
                    />
                  </LottieLink>
                </Tippy>
              </InnerColumnIcons>
            </ColumnLeft>
            <ColumnRight>
              <Heading>Links</Heading>
              <InnerColumnLinks className="innerColumn--links">
                <FooterReactLink to="/">home page</FooterReactLink>
                <FooterReactLink to="/hiPal">contact me</FooterReactLink>
                <FooterReactLink to="/myPosts">articles page</FooterReactLink>
                <FooterReactLink
                  as="a"
                  rel="nofollow noopener"
                  href="https://youtu.be/gO6wh3-AOD4"
                  target="_blank"
                >
                  play on YouTube
                </FooterReactLink>
                <FooterReactLink
                  as="a"
                  rel="nofollow noopener"
                  href="https://www.vegeloper.com/resume.pdf"
                  target="_blank"
                >
                  pdf resume
                </FooterReactLink>
              </InnerColumnLinks>
            </ColumnRight>
          </Chunk>
        </Row>
      </Container>
    </Box>
  );
};

export default Footer;
