//Types
import MessageMeActionTypes from "./message-me.types";

//Code
const INITIAL_STATE = {
  customerCountry: {name:"Not Selected", code: "N/A", dialCode: 0},
  richTextMessageAsHTML: "<p></p>",
  isLoading: false,
  isError: false,
  isComplete: false,
};


const MessageMeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MessageMeActionTypes.CUSTOMER_COUNTRY:
      return {
        ...state,
        customerCountry: action.payload,
      };
    case MessageMeActionTypes.CALL_TO_API_SUCCESS:
      return {
        ...state,
        isComplete: action.payload,
      };
    case MessageMeActionTypes.CALL_TO_API_FAILURE:
      return {
        ...state,
        isError: action.payload,
      };
    case MessageMeActionTypes.RICH_TEXT_MESSAGE_AS_HTML:
      return {
        ...state,
        richTextMessageAsHTML: action.payload,
      };
    case MessageMeActionTypes.SEND_DATA_SERVER_START:
      return {
        ...state,
      };
    case MessageMeActionTypes.SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER:
      return {
        ...state,
        isLoading: action.payload,
      };
    case MessageMeActionTypes.SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        isError: false,
      };
    case MessageMeActionTypes.SEND_CLIENT_RICH_TEXT_MESSAGE_TO_SERVER_FAILURE:
      return {
        ...state,
        isLoading: false,
        isComplete: true,
        isError: true,
      };
    case MessageMeActionTypes.UPDATE_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case MessageMeActionTypes.TOGGLE_IS_COMPLETE:
      return {
        ...state,
        isComplete: false,
      };

    default:
      return state;
  }
};

export default MessageMeReducer;
