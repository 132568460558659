//Framework
import React, { Suspense, lazy, Redirect } from "react";
//Libraries
import { Switch, Route, withRouter} from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
//Components
import HomePageNormal from "pages/home/home.page";
//Design
import {Wrapper} from './router-switch.styles';
import Spinner from "components/fallback-spinner/fallback-spinner.component";
import CustomSpinner from "components/fallback-custom-spinner/fallback-custom-spinner.component";
//HOC
import { WithSuspense } from "components/hoc-with-suspense/hoc-with-suspense.component";
//Hooks
import usePrevious from "handlers/prevRoute/prevRoute.hook";
//Lazy Import
const HiPalPage = lazy(() => import("pages/hiPal/hiPal.page"));
const MyWorksPage = lazy(() => import("pages/my-works/my-works.page"));
const TextArticlePage = lazy(() =>  import("pages/text-article/text-article.page"));
const FourZeroFourPage = lazy(() =>  import("pages/404/404.page"));
const FeedbackPage = lazy(() =>  import("pages/feedback/feedback.page"));
const MistakePage = lazy(() => import("pages/mistake/mistake.page"));


//Code
const HiPalWithSuspense = WithSuspense(HiPalPage);
const MyWorksWithSuspense = WithSuspense(MyWorksPage);
const TextArticlePageWithSuspense = WithSuspense(TextArticlePage);
const FourZeroFourPageWithSuspense = WithSuspense(FourZeroFourPage);
const FeedbackPageWithSuspense = WithSuspense(FeedbackPage);
const MistakePageWithSuspense = WithSuspense(MistakePage);

function RouterSwitch(props) {  
  const { location } = props;
  const prevAmount = usePrevious(location); 
  
  const historyProps = {
    prevAmount,
  }
  
  return (
    <Suspense fallback={<Spinner />}>
      <Wrapper>
        <TransitionGroup className="transition-group">
          <CSSTransition
            key={location.key}
            timeout={{ enter: 1300, exit: 1300 }}
            classNames="fade"
            style={{ background: "transparent" }}
          >
            <section
              className="route-section"
              style={{ background: "transparent" }}
            >
              <Suspense fallback={<CustomSpinner />}>
                <Switch location={location}>
                  <Route
                    exact
                    path="/"
                    render={(routeProps) => (
                      <HomePageNormal
                        {...historyProps}
                        {...routeProps}
                        pageActive="1"
                      />
                    )}
                  />

                  <Route
                    exact
                    path="/hipal"
                    render={(routeProps) => (
                      <HiPalWithSuspense
                        {...historyProps}
                        {...routeProps}
                        pageActive="2"
                      />
                    )}
                  />
                  <Route
                    exact
                    path="/myposts"
                    render={(routeProps) => (
                      <MyWorksWithSuspense
                        {...historyProps}
                        {...routeProps}
                        pageActive="4"
                      />
                    )}
                  />
                  <Route
                    path="/myposts/:slug"
                    render={(routeProps) => (
                      <TextArticlePageWithSuspense
                        {...historyProps}
                        {...routeProps}
                        pageActive="5"
                      />
                    )}
                  />
                  <Route
                    path="/notifications/feedback/:surveyId/yes"
                    component={FeedbackPageWithSuspense}
                  />
                  <Route
                    path="/notifications/feedback/:surveyId/no"
                    component={MistakePageWithSuspense}
                  />
                  <Route
                    path="not-found"
                    component={FourZeroFourPageWithSuspense}
                  />
                  <Route path="*" component={FourZeroFourPageWithSuspense} />
                  <Redirect to="/" />
                </Switch>
              </Suspense>
            </section>
          </CSSTransition>
        </TransitionGroup>
      </Wrapper>
    </Suspense>
  );
}


export default withRouter(RouterSwitch);
