//Libraries
import styled, { css, keyframes } from "styled-components";
import { Link as RouterLink} from "react-router-dom";
import {rgba} from 'polished';
import colors from 'design/colors';
import shadows from 'design/shadows';
import respond from 'design/responsive';

//Libraries
import {Link as ScrollLink} from "react-scroll";




//Code



const duration = "0.5s";

export const Overlay = styled.div`
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba("black", 0.5)};
  visibility: ${(p) => (p.isOpen ? "visible" : "hidden")};
  opacity: ${(p) => (p.isOpen ? 1 : 0)};
  transition-property: visibility opacity;
  transition-duration: ${duration};
`;



export const DialogOpenStyles = css`
  ${respond.tablet.standard`
    transform: translateX(-50%) translateY(-90vw) !important;
  `};
  ${respond.pc.standard`
    transform: translateX(-50%) translateY(-22vw) !important;
  `};
  
  transform: translateX(-50%) translateY(-95vw);
  opacity: 1;
  visibility: visible;
`;




export const Dialog = styled.div`
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 101;

  left: 50%;
  top: 100vh;
  font-size: 4vw;
  width: min-content;
  height: 300px;
  height: 80vw;
  height: min-content;
  width: 80vw;
  border-radius: 5vw;

  ${'' /* ${respond.tablet.standard`
    height: 75vw;
    width: 75vw;
  `} */}

  ${respond.pc.standard`
    height: min-content;
    width: 40vw;
    border-radius: 2vw;
    font-size: 1.4vw;
  `};

  background: ${colors.neutrals.light};
  transform: translateX(-50%) translateY(0);
  ${(p) => p.isOpen && DialogOpenStyles};
  transition: all 1s;
`;




export const DialogContent = styled.div`
  padding: 7vw;
  padding-bottom: 2.5vw;

  ${respond.pc.standard`
    padding-bottom: 1vw;
    padding: 2vw;
  `}
`;




export const GridContainerWrapper = styled.div`
  display: grid;
  ${'' /* grid-template-rows: 20vw 30vw 14vw; */}
  grid-template-rows: 25% 60% 15%;
  width: 100%;

  ${'' /* margin-top: 5%; */}

  ${respond.pc.standard`
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    margin-top: 2vw;
  `}
`;



export const GridContainer = styled.div`
  grid-row: 2 / span 1;
  ${'' /* display: grid; */}
  ${'' /* height: 100%; */}
display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1vw;
  ${"" /* margin-top: 1.5vw; */}
  width: 100%;
  ${"" /* height: 100%; */}
  margin-top: 5%;
  ${"" /* margin-top: 5%; */}
  ${respond.pc.standard`
    grid-template-rows: repeat(3, min-content);
    grid-column: 2/ span 1;
    grid-row: 1 / span 1;
    margin-top: 0;
    ${"" /* height: 10%; */}
  `}

  & > * {
    display: flex;
    align-items: center;
  }
`;

export const LocalLeft = styled(ScrollLink)`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &,
  &:link,
  &:visited {
    color: ${colors.secondary.darkest};
  }
  grid-row: 1 / span 1;
  justify-content: left;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;

  ${respond.pc.standard`
    height: min-content;
  `}
`;

export const LocalCenter = styled.a`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  grid-row: 2 / span 1;
  justify-content: left;
  background: transparent;
  border-radius: 6px;

  ${respond.pc.standard`
    height: min-content;
  `}
`;



export const LocalRight = styled(RouterLink)`
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  grid-row: 3 / span 1;
  justify-content: left;
  background: transparent;
  border-radius: 6px;

  ${respond.pc.standard`
    height: min-content;
  `}

  &,
  &:link,
  &:visited {
    color: ${colors.secondary.darkest};
  }
`;




export const SubCard = styled.div`
  &,
  &:link,
  &:visited {
    color: ${colors.secondary.darkest};
    ${"" /* color: ${colors.accent.lightest}; */}
  }
  box-shadow: ${shadows.neumorphic.original};
  width: 40vw;
  height: 10vw;

  margin-left: 6vw;

  ${'' /* border-radius: 6px; */}
  border-radius: 5.5vw;
  background: white;
  padding: 1vw;
  padding-left: 0.5vw;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-content: center;
  ${"" /* position: relative;  */}

  i {
    transition: color 0.2s ease-in;
    ${"" /* position: relative; */}
    ${
      "" /* width: 30vw;
  width: 20vw !important */
    }
    font-size: 7vw;
    color: ${colors.secondary.darkest};
    color: ${colors.accent.ochreFire};

    ${respond.pc.standard`
      font-size: 2vw;
    `}
  }

  &:hover i {
    color: ${colors.secondary.darker};
  }
  &:hover span {
    color: ${colors.neutrals.light};
  }

  div {
    width: 80%;
    position: relative;

    display: flex;
    justify-self: center;
    align-items: center;
  }

  span {
    margin-left: 2vw;
    font-family: "Poppins", sans-serif;
    font-size: 4vw;

    &,
    &:link,
    &:visited {
      color: ${colors.secondary.darkest};
    }

    ${respond.pc.standard`
      margin-left: 0.8vw;
      font-size: 1.3vw;
    `}
  }

  ${respond.pc.standard`
    width: 17vw;
    width: 100%;
    width: 12vw;
    height: 3vw;
    padding: 0.5vw;  
    padding-left: 0.15vw;
    margin-left: 1vw;
    border-radius: 2vw;
    
  `}

  :hover {
    background: ${colors.accent.ochreFire};
    color: white;
  }
`;

export const Header = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  justify-content: flex-end;
  ${"" /* background: red; */}

  & > .material-icons {
    color: ${rgba("#10132F", 0.38)};
    ${"" /* background: red; */}
  }

  & > .closer {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    background-color: white;
    transition: background 0.2s ease-in;
    transition: color 0.2s ease-in, background-color 0.2s ease-in;

    font-size: 7vw;

    ${respond.pc.standard`
    font-size: 2vw;
    `}
    :hover,
    :focus {
      background-color: ${colors.accent.lightest};
      color: white;
    }
  }
`;



export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${rgba("#10132F", 0.8)};
`;




export const Message = styled.div`
  grid-row: 1 / span 1;
  font-size: 1.2875rem;
  font-size: 6vw;
  display: ;
  line-height: 1.1;
  font-family: "Rubik Mono One", Cambria, "Times New Roman", Times, sans-serif;
  font-weight: 400;
  color: rgba("#10132F", 0.38);
  height: min-content;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    ${'' /* padding-bottom: 50%; */}
  }

  ${respond.pc.standard`
    grid-column: 1 / span 1;
    font-size: 2vw;
    ${"" /* padding: 0.5vw; */}
  `}

  .slide-up-sub-message {
    font-size: 4.2vw;
    font-family: "Poppins", sans-serif;

    ${respond.pc.standard`
      grid-column: 1 / span 1;
      font-size: 1.2vw;
    `}
  }
`;




export const SubMessage = styled.div`
  font-size: 2.5vw;
  font-weight: 200;
  color: ${colors.primary};

  ${respond.pc.standard`
    font-size: 0.8vw;
  `}
`;


const colorChange4 = (backColor) => keyframes`
  0% { background:  transparent;}
  40% { background:  ${backColor}; }
  100% { background:  ${backColor};  }

`;


export const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-size: 4.6vw;
  font-weight: 800;
  color: ${colors.primary};
  color: ${colors.accent.lightest};
  ${'' /* color: ${colors.primary}; */}

  font-family: "Poppins", sans-serif;
  height: 25px;
  height: 8.333vw;
  width: 25px;
  width: 8.333vw;
  border-radius: 50%;

  ${respond.pc.standard`
    font-size: 1vw;
    height: 2.5vw;
    width: 2.5vw;
  `}

  animation: ${(props) => colorChange4(props.backColor)}
    ${(props) => props.duration} linear ${(props) => props.delay} infinite;
`;



export const ReadMore = styled.div`
  grid-row: 3 / span 1;
  margin-top: 4.5vw;
  color: red;
  color: #eb5e28;
  font-size: 10px;
  font-size: 3vw;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: flex-end;
  padding-right: 0vw;
  padding-top: 5%;
  padding-bottom: 5%;

  ${respond.pc.standard`
    grid-row: 1 / span 1;
    grid-column: 1/ span 1;
    margin-top: 50%;
    padding: 0.5vw
    padding-left: 0.6vw;
    justify-content: flex-start;
    height: min-content;
    padding-right: 2vw;
    padding-top: 5%;
      padding-bottom: 0%;

  `}

  .slide-up-read-more--link {
    &,
    &:link,
    &:visited {
      color: #eb5e28;
      font-size: 3.3vw;
      transition: color 0.2s ease-in;

      ${respond.pc.standard`
        font-size: 1.3vw;
      `}
    }

    &:hover {
      color: ${colors.accent.ochreFire};
    }
  }

  div {
    line-height: 1.2;
    justify-self: flex-end;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;