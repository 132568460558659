//Framework
import React, {Suspense} from "react";
//Components
import CustomSpinner from "components/fallback-custom-spinner/fallback-custom-spinner.component";




//Code
export const WithSuspense = (WrappedComponent) => (props) => {
  const { prevAmount  } = props;

  if (prevAmount) {
    let prevRoute = prevAmount.pathname;
    
    if (prevRoute.toLowerCase() === "refresh start") {
      return (
        <Suspense fallback={<CustomSpinner />}>
          <WrappedComponent {...props} />
        </Suspense>
      );
    }
    if (prevRoute.toLowerCase() === "/") {
      return (
        <Suspense fallback={<CustomSpinner />}>
          <WrappedComponent {...props} />
        </Suspense>
      );
    }
    if (prevRoute.toLowerCase() === "/hipal") {
     return (
       <Suspense fallback={<CustomSpinner />}> 
         <WrappedComponent {...props} />
       </Suspense>
     );
    }
    if (prevRoute.toLowerCase() === "/myposts") {
     return (
       <Suspense fallback={<CustomSpinner />}>
         <WrappedComponent {...props} />
       </Suspense>
     );
    }
    if (prevRoute.toLowerCase() === "/myposts/:id") {
     return (
       <Suspense fallback={<CustomSpinner />}>
         <WrappedComponent {...props} />
       </Suspense>
     );
    }
  } 

 
  return (
    <Suspense fallback={<CustomSpinner />}>
      <WrappedComponent {...props} />
    </Suspense>
  );
};
