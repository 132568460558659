export const frontEnd = [
  "react",
  "redux",
  "nodejs",
  "typescript",
  "graphql",
  "redis",
  "postgresql",
  "mongodb",
  "pythonlang",
  "jest",
];

export const frontEndNames = [
  "React",
  "Redux",
  "Node.js",
  "TypeScript",
  "GraphQL",
  "Redis",
  "PostgreSQL",
  "MongoDB Atlas",
  "Python",
  "Jest",
];

export const backEnd = [
  "aws",
  "googlecloud",
  "firebase",
  "jwt",
  "oauth",
  "passport",
  "circleci",
  "cloudflare",
  "docker",
  "gitlab",
];

export const backEndNames = [
  "AWS",
  "Google Cloud",
  "Firebase",
  "JSON web token",
  "oAuth 2",
  "Passport.js",
  "CircleCI",
  "Cloudflare CDN",
  "Docker",
  "GitLab",
];
