//Libraries
import styled from 'styled-components';

export const SpinnerOverlay = styled.div`
  ${"" /* height: 100%; */}
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  ${'' /* background: #003153; */}
`;
