//Framework
import React from 'react';
//Design
import colors from 'design/colors';
//Design
import { SectionHeading, HeadingContainer, Heading, LinkToPage, NoLink, CTA } from "./heading.styles";

//Code
const Template =  React.forwardRef((props, ref) => {
  const {text, subCta, cta, inverted, extra, topMargin, hasLink, noMarginBottomOnMobile, youtubePlayer, margin} = props;
  
  const handleClick = (e) => {

    if (ref.current) {
      var playPromise = ref.current.play();
      
      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
          })
          .catch((error) => {
          });
      }
    } 
  };

  return (
    <SectionHeading
      className="section__heading"
      style={props.zeroMarginBottom && { marginBottom: "0" }}
      zeroMargin={props.zeroMargin}
      topMargin={topMargin}
      noMarginBottomOnMobile={noMarginBottomOnMobile}
      margin={margin}
      topMarginSmall={props.topMarginSmall}
    >
      <HeadingContainer>
        <Heading
          extra={extra}
          zeroMargin={props.zeroMargin}
          topPaddingSmall={props.topMarginSmall}
        >
          <h1>{text}</h1>

          {hasLink &&
            (!inverted ? (
              <h2>
                {subCta}
                <CTA>
                  <NoLink onClick={youtubePlayer && handleClick}>{cta}</NoLink>
                </CTA>
              </h2>
            ) : (
              <h2>
                <CTA>
                  <LinkToPage to={hasLink}>{cta}</LinkToPage>
                </CTA>
                {subCta}
              </h2>
            ))}

          {!hasLink &&
            (!inverted ? (
              <h2>
                {subCta}
                <span
                  style={{
                    color: `${colors.accent.lightest}`,
                    fontWeight: 400,
                  }}
                >
                  {cta}
                </span>
              </h2>
            ) : (
              <h2>
                <span
                  style={{
                    color: `${colors.accent.lightest}`,
                    fontWeight: 400,
                  }}
                >
                  {cta}
                </span>
                {subCta}
              </h2>
            ))}
        </Heading>
      </HeadingContainer>
    </SectionHeading>
  );
});

export default Template;
