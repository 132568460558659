//Basics
import React from 'react';
//Design
import {
  HigherContainer,
  HeaderContainer,
  LogoContainer,
  LogoSVG,
  LogoLine,
  LogoText,
  MenuButtonsContainer,
  MenuButton,
  MenuButtonIcon,
  PrussianBlueToOrange,
  Icon,
} from "./top-menu.styles";


//Code
export const Header = (props) => {
  const { pageActive } = props;


  return (
    <HigherContainer>
      <HeaderContainer id="topMenu">
        <LogoContainer variant="active" to="/">
          <LogoSVG alt="Vegeloper.com  Logo" />
          <LogoLine alt="Seperator Icon" />
          <LogoText alt="Vegeloper.com wordmark">vegeloper</LogoText>
        </LogoContainer>
        <MenuButtonsContainer>
          <MenuButton pageNumber="2" pageActive={pageActive} to="/hipal">
            /hi
            <PrussianBlueToOrange>Pal</PrussianBlueToOrange>
          </MenuButton>
          <MenuButton pageNumber="4" pageActive={pageActive} to="/myposts">
            /my
            <PrussianBlueToOrange>Posts</PrussianBlueToOrange>
          </MenuButton>
          <MenuButtonIcon pageNumber="1" pageActive={pageActive} to="/">
            <Icon className="material-icons hi">home</Icon>
          </MenuButtonIcon>
        </MenuButtonsContainer>
      </HeaderContainer>
    </HigherContainer>
  );
};



export default Header;
