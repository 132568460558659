//Libraries
import { css } from "styled-components";

//Code
export const respond = {
  pc: {
    max_4k: (...args) => css`
      @media (min-width: 3800px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
    max: (...args) => css`
      @media (min-width: 2000px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
    large: (...args) => css`
      @media (min-width: 1600px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
    medium: (...args) => css`
      @media (min-width: 1400px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
    small: (...args) => css`
      @media (min-width: 1350px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
    min: (...args) => css`
      @media (min-width: 500px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
    standard: (...args) => css`
      @media (min-width: 500px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
  },
  tablet: {
    max: (...args) => css`
      @media (min-width: 1100px) and (hover: none) and (pointer: coarse) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
    large: (...args) => css`
      @media (min-width: 1000px) {
        ${css(...args)};
      }
    `,
    medium: (...args) => css`
      @media (min-width: 828px) {
        ${css(...args)};
      }
    `,
    small: (...args) => css`
      @media (min-width: 768px) {
        ${css(...args)};
      }
    `,
    min: (...args) => css`
      @media (min-width: 600px) and (max-aspect-ratio: 16/9) {
        ${css(...args)};
      }
    `,
    standard: (...args) => css`
      @media (min-width: 600px) and (max-aspect-ratio: 16/9) {
        ${css(...args)};
      }
    `,
    landscape: (...args) => css`
      @media (min-width: 600px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
  },
  mobile: {
    max: (...args) => css`
      @media (min-width: 600px) {
        ${css(...args)};
      }
    `,
    large: (...args) => css`
      @media (min-width: 540px) {
        ${css(...args)};
      }
    `,
    medium: (...args) => css`
      @media (min-width: 480px) {
        ${css(...args)};
      }
    `,
    small: (...args) => css`
      @media (min-width: 412px) {
        ${css(...args)};
      }
    `,
    min: (...args) => css`
      @media (min-width: 320px) {
        ${css(...args)};
      }
    `,
    standard: (...args) => css`
      @media (min-width: 300px) {
        ${css(...args)};
      }
    `,
    landscape: (...args) => css`
      @media (min-width: 300px) and (orientation: landscape) {
        ${css(...args)};
      }
    `,
  },
};



export default respond;