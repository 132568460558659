//Framework
import React, { useState } from "react";
//Libraries
import Tippy from "@tippyjs/react";
//Design
import { Row, Underline, Button, Content, Card, Tab, Image } from "./tabbed-card-neumorphic.styles";

//Code
const tabs = ["Goal", "Challenge", "Stack"];


export const Tabs = ({active, setActive}) => (
  <Row>
    <Underline active={active} />
    {tabs.map((tab, index) => (
      <Button active={active === index} onClick={() => setActive(index)}>
        {tab}
      </Button>
    ))}
  </Row>
);


export const TabbedCard = (props) => {
  const { techOne, techOneIcons, techTwo, techTwoIcons, goal, challenge  } = props;
  const [active, setActive] = useState(0);

  const fallbackAlt = "Official Logo";
  const fallbackIcon = "https://res.cloudinary.com/khashi-dev/image/upload/v1627282158/react_um0uy6.svg";
  


  return (
    <Card className="tabbedcard__container">
      <Content active={active} className="tabbedcard__content">
        <Tab className="tabbedcard__text--goal">{goal}</Tab>
        <Tab className="tabbedcard__text--challenge">{challenge}</Tab>
        <Tab className="tabbedcard__text--stack">
          {techOne || "Front-End"}:
          <div style={{ display: "flex", marginButtom: "1vw" }}>
            {techOneIcons &&
              techOneIcons.icons.map((icon, i) => (
                <a
                  target="_newtab"
                  rel="nofollow noopener"
                  href={icon.link ? icon.link : "www.vegeloper.com"}
                >
                  <Tippy
                    content={icon.alt}
                    placement={i === 0 ? "left" : "right"}
                    animation="scale-subtle"
                    arrow={true}
                    duration={200}
                    delay={[75, 0]}
                    distance={8}
                    className="tippy-icons-theme"
                  >
                    <Image
                      src={icon.src ? icon.src : fallbackIcon}
                      alt={icon.alt ? icon.alt : fallbackAlt}
                    />
                  </Tippy>
                </a>
              ))}
          </div>
          {techTwo || "Back-End"}:
          <div style={{ display: "flex" }}>
            {techTwoIcons &&
              techTwoIcons.icons.map((icon, i) => (
                <a
                  target="_newtab"
                  rel="nofollow noopener"
                  href={icon.link ? icon.link : "www.vegeloper.com"}
                >
                  <Tippy
                    content={icon.alt}
                    placement={i === 0 ? "left" : "right"}
                    animation="scale-subtle"
                    arrow={true}
                    duration={200}
                    delay={[75, 0]}
                    distance={8}
                    className="tippy-icons-theme"
                  >
                    <Image
                      src={icon.src ? icon.src : fallbackIcon}
                      alt={icon.alt ? icon.alt : fallbackAlt}
                    />
                  </Tippy>
                </a>
              ))}
          </div>
        </Tab>
      </Content>
      <Tabs
        active={active}
        setActive={setActive}
        className="tabbedcard__tabs"
      />
    </Card>
  );
};

export default TabbedCard;