//Framework
import React from 'react';
//Components
import CardScroll from 'components/card-scroll/card-scroll.component';
//Style
import {
  ScrollContainerFlex,
  ScrollCardContainer,
} from "./scroll-horizontal.styles";

//Code
const ScrollHorizontal = () => {

  const cardScrollPropsOne = {
    project: "Authentication",
    projectSub: "Process",
    photo: "react",
    goal: " A seamless Auth process is almost invisible, you don't see it but it's always there.",
    challenge:
      " The real challenge is to perfectly blend several Auth providers like Google, FB & GitHub with your default flows.",
    techOne: "Auth Technologies",
    techOneIcons: {
      icons: [
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630258034/jwt_yd89a0.svg",
          alt: "JWT official",
          link: "https://jwt.io/",
        },
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630257727/firebase_sgthfu.svg",
          alt: "Firebase official",
          link: "https://firebase.google.com/",
        },
      ],
    },
    techTwo: "Back-End",
    techTwoIcons: {
      icons: [
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630339799/expressjs-icon_hkulgn.svg",
          alt: "Express official",
          link: "https://expressjs.com/",
        },

        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630257848/passport_bi3cpf.svg",
          alt: "Passport official",
          link: "http://www.passportjs.org/",
   
        },
      ],
    },
  };
  const cardScrollPropsTwo = {
    project: "Cognitive",
    projectSub: "Science",
    photo: "cognitive",
    goal: "This project is an effort to better understand human memory in decision making context.",
    challenge:
      " The most challenging part is mapping the real complex cognitive problem into a functional code.",
    techOne: "User Interface",
    techOneIcons: {
      icons: [
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1627282158/react_um0uy6.svg",
          alt: "React official",
          link: "https://reactjs.org/",
        },
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630342836/graphql_ioj3ji.svg",
          alt: "GraphQL official",
          link: "https://graphql.org/",
        },
     
      ],
    },
    techTwo: "Logic",
    techTwoIcons: {
      icons: [
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1627282158/nodejs_kmmofl.svg",
          alt: "Node.js official",
          link: "https://nodejs.org/en/",
        },
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630259775/googlecloud_moxzcw.svg",
          alt: "Google Cloud official",
          link: "https://cloud.google.com/",
        },
     
      ],
    },
  };
  const cardScrollPropsThree = {
    project: "Ayoco",
    projectSub: "Project",
    goal: "Content is King, famously noted Bill Gates, in 1996 way before the Instagram era.",
    challenge:
      "The hard part is that creating a performant website with dynamic content changes is not always that easy.",
    techOne: "Content ",
    techOneIcons: {
      icons: [
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630389833/cloudinary_cloud_glyph_regular_ug0gbq.svg",
          alt: "Cloudinary official",
          link: "https://cloudinary.com/",
        },
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630341828/contentful-logo_xkmirk.svg",
          alt: "Contentful official",
          link: "https://www.contentful.com/",
        },
      ],
    },
    techTwo: "Cloud Technologies",
    techTwoIcons: {
      icons: [
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630393996/circleci-icon_kss9hp.svg",
          alt: "CircleCI official",
          link: "https://circleci.com/",
        },
        {
          src: "https://res.cloudinary.com/khashi-dev/image/upload/v1630343904/aws_jbvn8b.svg",
          alt: "AWS official",
          link: "https://aws.amazon.com/",
        },
      ],
    },
  };
  
  return (
    <ScrollContainerFlex>
      <ScrollCardContainer>
        <CardScroll {...cardScrollPropsOne} />
      </ScrollCardContainer>
      <ScrollCardContainer>
        <CardScroll {...cardScrollPropsTwo} />
      </ScrollCardContainer>
      <ScrollCardContainer>
        <CardScroll  {...cardScrollPropsThree} />
      </ScrollCardContainer>
    </ScrollContainerFlex>
  );
};

export default ScrollHorizontal;
