//Types
import GlobalActionTypes from "redux/global/global.types";

const INITIAL_STATE = {
  menuOpen: false,
  popUpOpen: false,
  error: null,
  darkMode: false,
  emailRichText: null,
};

const GlobalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GlobalActionTypes.TOGGLE_MENU_OPEN:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    case GlobalActionTypes.CLOSE_MENU:
      return {
        ...state,
        menuOpen: false,
      };
    case GlobalActionTypes.EMAIL_RICH_TEXT:
      return {
        ...state,
        emailRichText: action.payload,
      };
    case GlobalActionTypes.TOGGLE_POP_UP:
      if (action.payload === false || action.payload === true) {
        return {
          ...state,
          popUpOpen: action.payload,
        };
      } else {
        return {
          ...state,
          popUpOpen: !state.popUpOpen,
        };
      }

    case GlobalActionTypes.TOGGLE_DARK_MODE:
      return {
        ...state,
        darkMode: true,
      };

    default:
      return state;
  }
};

export default GlobalReducer;
