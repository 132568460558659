//Libraries
import styled from "styled-components";
import {Link as ScrollLink} from "react-scroll";
//Responsive
import respond from "design/responsive";
//Design
import colors from "design/colors";


export const ScrollDownCTA = styled(ScrollLink)`
  grid-column: center-start/ center-end;
  width: 100%;
  height: 18vw;
  align-self: flex-start;

  grid-row: 3 / span 1 !important;
  display: flex;
  margin-top: clamp(2rem, 6.66666vw, 6.66666vw);
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${respond.tablet.standard`
  height: 17vw;
  `}

  ${respond.pc.standard`
  margin-top: 0.9765625vw;
  height: 8vw;
  `}
`;

export const ArrowContainer = styled(ScrollLink)`
  margin-top: -2rem;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :hover > svg path {
    stroke: ${colors.accent.ochreFire};
    stroke: ${colors.accent.darkest};
    stroke: ${colors.accent.lightest};
  }
`;

export const Arrow = styled.svg`
  height: 80px;
  width: 45px;
  transform: scale(0.6);
  ${respond.mobile.standard`
  transform: scale(0.8);
  `}
  ${respond.mobile.medium`
  transform: scale(0.85);
  `}
  ${respond.mobile.max`
  transform: scale(1);
  `}

  ${respond.tablet.standard`
   transform: scale(1.5);
  `}

  ${respond.tablet.large`
  ${"" /* transform: scale(1.5); */}
   transform: scale(2);
  `}

  ${respond.pc.min`
  transform: scale(1);
  `}
  ${respond.pc.medium`
  transform: scale(1.3);
  `}
  
  ${respond.pc.large`
  transform: scale(1.5);
 
  `}
  ${respond.pc.max`
  transform: scale(4.5);
  `}
  
  
  
  path {
    stroke: #ffffff;
    width: 50px;
    height: 150px;
    stroke: ${colors.accent.lightest};
    stroke: ${colors.accent.ochreFire};
    transition: stroke 0.45s;
    fill: transparent;

    stroke-width: 4px;
    animation: arrowDown 2s infinite;
  }
  path.a1 {
    animation-delay: -0.5s;
  }

  @keyframes arrowDown /*Safari and Chrome*/ {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    80% {
      opacity: 1;
      transform: translateY(50px);
    }
    100% {
      opacity: 0;
      transform: translateY(50px);
    }
  }
`;
