//Franework
import React from "react";

//Code
class ErrorBoundary extends React.Component {
  constructor() {
    super();

    this.state = {
      hasErrored: false,
      isPaused: false,
      isStopped: false,
    };
  }

  static getDerivedStateFromError(error) {
    // process the error
    return { hasErrored: true };
  }

  componentDidCatch(error, info) {
    console.log(error);
    console.log(info);
  }

  render() {
    if (this.state.hasErrored) {
      return (
        <div>
          <h1>This is error-boundary page.</h1>
          <h2>We hit an internal error. </h2>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
