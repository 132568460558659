//Framework
import React, {Suspense} from "react";
//Components
import SpinnerComponent from "components/fallback-spinner-component/fallback-spinner-component.component";


//Code
export const WithSuspenseYoutubeComponent = (WrappedComponent) => React.forwardRef((props, ref)  => {
 
  return (
    <Suspense fallback={<SpinnerComponent />}>
      <WrappedComponent {...props} ref={ref} />
    </Suspense>
  );
});
