//Framework
import React, { lazy, useEffect, useRef } from "react";
//Libraries
import { Element } from "react-scroll";
//Containers
import { VectorBackground } from "design/containers";
//Sections
import Heading from "sections/heading/heading.component";
import TopMenu from "sections/top-menu/top-menu.component";
import ScrollDown from 'sections/scroll-down/scroll-down.component';
import Footer from "sections/footer/footer.component";
import Skills from 'sections/skills/skills.component';
import ScrollHorizontal from "sections/scroll-horizontal/scroll-horizontal.component";
import MoreOptions from "sections/more-options/more-options.component";
//HOC
import { WithSuspenseYoutubeComponent } from "components/hoc-with-youtube/hoc-with-youtube.component";
//Components
// import YoutubeSimple from "sections/youtube/youtube.component";
// import ReturnSimple from "sections/return/return.component";
import HeroHeaderRightSimple from "sections/hero-header-right/hero-header-right.component";
import HeroHeaderLeftSimple from "sections/hero-header-left/hero-header-left.component";
//Lazy Import
const YouTube = lazy(() => import("sections/youtube/youtube.component"));
const Return = lazy(() => import("sections/return/return.component"));
// const ScrollHorizontalLazy = lazy(() => import("sections/scroll-horizontal/scroll-horizontal.component"));
// const SkillslLazy = lazy(() => import("sections/skills/skills.component"));
// const MoreOptionsLazy = lazy(() => import("sections/more-options/more-options.component"));
// const FooterLazy = lazy(() => import("sections/footer/footer.component"));
// 
// const HeroHeaderRight = lazy(() =>
//   import("sections/hero-header-right/hero-header-right.component")
// );
// const HeroHeaderLeft = lazy(() =>
//   import("sections/hero-header-left/hero-header-left.component")
// );

//Code
// const ScrollHorizontalWithSuspense = WithSuspenseYoutubeComponent(ScrollHorizontalLazy);
// const SkillsWithSuspense = WithSuspenseYoutubeComponent(SkillslLazy);
// const MoreOptionsWithSuspense = WithSuspenseYoutubeComponent(MoreOptionsLazy);
// const FooterWithSuspense = WithSuspenseYoutubeComponent(FooterLazy);
const YouTubeWithSuspense = WithSuspenseYoutubeComponent(YouTube);
const ReturnWithSuspense = WithSuspenseYoutubeComponent(Return);
// const HeroHeaderRightWithSuspense = WithSuspenseYoutubeComponent(HeroHeaderRight);
// const HeroHeaderLeftWithSuspense = WithSuspenseYoutubeComponent(HeroHeaderLeft);

const HomeContent = (props) => {
  const { location, prevAmount, pageActive} = props;

  const myRefTag = useRef(null);
  const myRefTag2 = useRef(null);
  const myRef2 = useRef(null);

  const secretRef = React.createRef();
  const elementRef = useRef();

  useEffect(() => {
    if (!prevAmount) {
      window.scrollTo(0, 0);
    } else if (prevAmount.pathname === location.pathname) {
    } else if (prevAmount.pathname.toLowerCase() === "/myposts") {
      window.scrollTo(0, document.body.scrollHeight / 5);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const returnProps = {
    lottieUrl: "https://assets6.lottiefiles.com/packages/lf20_sxqv16nz.json",
    linkTo: "/myposts",
    header: "Did you know it?",
    opening: `not everyone can tolerate an oldfashion pdf resume!`,
    text: `react`,
    icon: "react",
    subHeader: "react",
    lottieHeight: "100%",
  };

  const moreOptionsProps = {
    linkToSmall: "https://www.linkedin.com/in/khashi-akhavan/",
    linkToBig: "/hipal",
    subjectBig: "developer",
    subjectSmall: "linkedin",
    ctaBig: "Mail Me",
    ctaSmall: "Visit Me",
    customTarget: "_blank",
  };


  return (
    <VectorBackground className="home-page-container vectorBackground">
      <TopMenu pageActive={pageActive} />
      <HeroHeaderLeftSimple
        className="heroHeader-left"
        name="firstInsideContainer"
      />
      <HeroHeaderRightSimple />
      <ScrollDown />
      <Element
        name="youtube-player-tag"
        id="youtube-player-tag"
        className="element"
        ref={myRefTag}
      />
      <Heading
        cta="play"
        subCta="just hit "
        text="Wanna know me in a minute?"
        noMarginBottomOnMobile
        hasLink
        youtubePlayer
        ref={secretRef}
      />
      <Element
        name="youtube-player-tag2"
        id="youtube-player-tag2"
        className="element"
        ref={myRefTag2}
      />
      <YouTubeWithSuspense id="resume-player" ref={secretRef} />
      <Element
        name="youtube-player-tester"
        id="youtube-player-tester"
        className="element"
        ref={myRef2}
      />
      <Skills ref={elementRef} />
      <Heading
        cta="read "
        subCta="more details"
        text="Current Projects"
        inverted
        zeroMarginBottom
      />
      <ScrollHorizontal />
      <ReturnWithSuspense {...returnProps} />
      {/* <ReturnSimple {...returnProps} /> */}
      <MoreOptions {...moreOptionsProps} />
      <Footer />
    </VectorBackground>
  );
};;

export default HomeContent;
