//Libraries
import { createGlobalStyle } from 'styled-components';
//Responsive
import respond from 'design/responsive';
//Styles
import fonts from 'design/fonts';
import colors from 'design/colors';


const GlobalStyle = createGlobalStyle`


::selection {
    background-color: ${colors.primary};
    color: ${colors.white};
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
 
}


a,
a:link,
a:hover {
	text-decoration: none;
  text-decoration-color: currentColor;
   
}

a:visited {
  text-decoration: none;
  color: currentColor;
  text-decoration-color: currentColor;
   
}

html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    // This defines what 1rem is
    // 1rem = 10px; 10px/16px = 62.5%
    font-size: 62.5%; /* 10px */    
     scrollbar-color: #eb5e28 white !important;
     scrollbar-width: thin; */
}

  body::-webkit-scrollbar-track {
         -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
      }
      body::-webkit-scrollbar {
        width: 10px;
        cursor: pointer;
        background-color: #F5F5F5;
      }
      body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        cursor: pointer;
        background-color: #eb5e28;
        
      }

body {
    ${fonts.text};
    font-display: swap;
    color: ${colors.primary};
    line-height: 1.6;
    background: transparent;

    
    
    *:focus {
      outline: none;
    }
}

.border-success {
  border: 0.5vw dashed !important;
  border-color: hsl(120,76%,50%) !important;

  ${respond.pc.standard`
    border: 0.15vw dashed !important;
     border-color: hsl(120,76%,50%) !important;
  `}
}
.border-failure {
  border: 0.5vw dashed !important;
  border-color: #EB5E28  !important;

  ${respond.pc.standard`
    border: 0.15vw dashed !important;
    border-color: #EB5E28  !important;
  `}
}

//styling the theme of tippyJS
.tippy-icons-theme {
    background-color: #102539 !important;
    color: #F9F9FA !important;

    font-family: Raleway, sans-serif;
    font-size: 3vw !important;  

    line-height: 1.2;
    border-radius: 0.3vw !important;
    
    ${respond.pc.standard`
      font-size: 1.1vw !important;  
    `} 
    
    //styling the border of tippyJS
    .tippy-arrow { 
      border-top-color: tomato !important;
      background: transparent;
      color: #102539;
    }    
}

`;


export default GlobalStyle;
