//Libraries
import axios from 'axios';
import { takeLatest, all, call } from 'redux-saga/effects';
//Types
import GlobalActionTypes from "redux/global/global.types";


export function* CallToAPI({ payload: { URL, data }}) {
  try {
    const response = yield axios.post(URL, {data: data});
  } catch (error) {

  }
}

export function* onSendDataToServer() {
  yield takeLatest(GlobalActionTypes.SEND_OBJECT_TO_SERVER_START, CallToAPI);
}

export function* globalSagas() {
  yield all([
    call(onSendDataToServer)
  ]);
}

