//Libraries
import { createSelector } from 'reselect';

const selectGlobal = state => state.global;

export const selectMenuOpen = createSelector(
  [selectGlobal],
  (pageState) => pageState.menuOpen
);
export const selectPopUpOpen = createSelector(
  [selectGlobal],
  (pageState) => pageState.popUpOpen
);
export const selectRichText = createSelector(
  [selectGlobal],
  (pageState) => pageState.emailRichText
);
