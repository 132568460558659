//Libraries
import styled from "styled-components";
import {Link} from 'react-router-dom';
import { rgba } from 'polished';
//Responsive
import respond from "design/responsive";
//Design
import colors from 'design/colors';
import fonts from 'design/fonts';
//Assets
import { ReactComponent as Logo } from "assets/SVG/Vegeloper-mini.svg";


//Code
export const Box = styled.div`
  &,
  & > * {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
  grid-column: cover-start / cover-end;
  background: black;
  background: ${rgba(colors.neutrals.light, 0.55)};
  position: absolute;
  position: relative;

  ${respond.pc.standard`
  `};
  bottom: 0;
  width: 100%;
  width: 100vw;
  @media (max-width: 1000px) {
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 6vw auto 6vw;

  ${respond.pc.standard`
          margin: 3vw auto 3vw;
  `};
`;

export const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${respond.pc.standard`
  `};
`;

export const UpperContainer = styled.div`
  padding-top: 2vw;
`;

export const Copyright = styled.h4`
  padding-top: 2vw;

  ${fonts.heading.h1_sub_light};

  font-size: 3vw;
  ${respond.pc.standard`
      padding-top: 1vw;
      font-size: 1.3vw;
  `};
`;

export const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  margin-left: 12%;

  height: 40vw;

  ${respond.pc.standard`
    height: 13vw;
    margin-left: 10.5%;
  `}
`;

export const ColumnRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  height: 40vw;

  ${respond.pc.standard`
    height: 13vw;
  `}
`;

export const InnerColumnIcons = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  height: 30vw;

  ${respond.pc.standard`
    height: 10vw;
  `}
`;

export const InnerColumnLinks = styled.div`
  height: 30vw;

  ${respond.pc.standard`
    height: 10vw;
  `}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
`;

export const Row = styled.div`
  display: flex;
  grid-template-columns: repeat(2, minmax(100px, 10vw));
  grid-gap: 0.2vw;
  justify-content: space-evenly;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(50px, 27vw));
  }
`;

export const Chunk = styled.div`
width: 60%;

${respond.pc.standard`
width: 25%;

`}
display: flex;
 
  grid-template-columns: repeat(2, minmax(100px, 10vw));
  justify-content: space-between;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(50px, 27vw));
  } */}
`;

export const FooterLink = styled.a`
  color: ${colors.primary};
  color: ${colors.secondary.darkest};

  margin-bottom: 2.5vw;

  ${respond.pc.standard`
          font-size: 2vw;
            margin-bottom: 0.8vw;
      `}
  text-decoration: none;


  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: clamp(11px, 1.1vw, 1.1vw);
  line-height: 1.6;
  font-size: 3vw;
  transition: 200ms ease-in-out;

  ${respond.pc.standard`
          font-size: 1vw;
  `}

  &:hover {
    color: ${colors.accent.ochreFire};
    color: ${colors.accent.darkest};
  }
`;

export const FooterReactLink = styled(Link)`
  color: ${colors.primary};
  color: ${colors.secondary.darkest};


  ${respond.pc.standard`
          font-size: 2vw;
      `}
  text-decoration: none;


  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: clamp(11px, 1.1vw, 1.1vw);
  line-height: 1.6;
  font-size: 3vw;
  transition: 200ms ease-in-out;

  ${respond.pc.standard`
          font-size: 1vw;
      `}

  &:hover {
    color: ${colors.accent.ochreFire};
    color: ${colors.accent.darkest};
  }


`;

export const LottieLink = styled.a`
  position: relative;
  flex-grow: 1;
  width: 7vw;
  height: 7vw;
  color: ${colors.primary};
  color: ${colors.secondary.darkest};

  ${respond.pc.standard`
          width: 2.5vw;
          height: 2.5vw;
          font-size: 2vw;
      `}
  text-decoration: none;


  font-family: Montserrat, sans-serif;
  font-weight: 800;
  font-size: clamp(11px, 1.1vw, 1.1vw);
  line-height: 1.6;
  font-size: 3vw;
  transition: 200ms ease-in-out;

  ${respond.pc.standard`
          font-size: 1vw;
      `}

  &:hover {
    color: ${colors.accent.ochreFire};
    color: ${colors.accent.lightest};
  }

   :hover {
    filter: hue-rotate(0.420turn)  brightness(150%) ;
  }

    &:active,
    &:focus,
    &:visited {
   color: ${colors.secondary.darkest};
    }
`;

export const Heading = styled.p`
  font-family: "Rubik Mono One", Cambria, "Times New Roman", Times, sans-serif;
  font-size: 3.5vw;
  font-weight: 400;
  line-height: 1;

  ${respond.pc.standard`
        font-size: 1.2vw;
      `}
  color: #fff;
  color: ${colors.accent.darkest};
  transition: filter 0.25s ease-in;
  :hover {
    filter: brightness(1.25);
  }
`;

export const SvgLogo = styled(Logo)`
 width: 20vw;
 height: auto;

 ${respond.pc.standard`
  width: 7vw;
 `}
`;
