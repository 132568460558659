//Libraries
import styled, { css } from "styled-components";
import {rgba} from 'polished';
import { Link } from "react-router-dom";
//Design
import colors from 'design/colors';
//Responsive
import respond from 'design/responsive';


export const NavContainer = styled.div`
  display: ${({ isShown }) => (isShown ? 'auto' : "none")};
`;

export const Nav = styled.nav`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  top: 50vh;
  transform: translateY(-50%);
`;


export const NavButton = styled.button`
  display: flex;
  border: none;
  align-items: flex=end;
  align-self: flex-end;
  justify-content: flex-start;
  height: 44px;
  height: 14.666666vw;
  height: min-content;

  background: transparent;
  color: ${rgba("white", 0.5)};



  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  &:focus,
  &:hover,
  &:active {
    border: none;
    outline: none;
  }
`;

export const NavIcon = styled.span`
  font-size: 3.5rem;
  font-size: 9vw;
  font-size: clamp(12px, 10.5vw, 10.5vw);
  align-self: flex-end;
  transition: all 0.4s;
  color: #102539;

  ${respond.tablet.standard`
    font-size: 8vw;
  `}

  ${respond.pc.standard`
    font-size: 3.5vw;
  `}
  

  border: none;
  outline: none;

  &:focus,
  &:active {
    ${"" /* color: red; */}
  }
`;

export const NavText = styled.span`
  font-family: "Poppins", sans-serif;
  align-self: flex-end;
  align-self: center;
  font-size: 8.3333333vw;
  font-size: 7vw;
  margin-left: 1vw;
  line-height: 1.15;

  ${respond.tablet.standard`
    font-size: 6vw;
  `}
  ${respond.pc.standard`
    font-size: 2.5vw;
     margin-left: 0.3vw;
       line-height: 1;
  `}
  font-weight: 800;

  color: #f0f0f3;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const NavLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const inputOpenStyles = css`
  &:hover span:nth-of-type(1) {
    transform: rotatez(45deg) translate(-3vw, 7.3vw);

    ${respond.pc.standard`
    transform: rotatez(45deg) translate(-1.2vw, 2vw);
    `}
  }
  &:hover span:nth-of-type(3) {
    transform: translate(0vw, -5vw) rotatez(45deg);

    ${respond.pc.standard`
      transform: translate(0vw, -1.6666666vw) rotatez(45deg);
    `}
  }
  span:nth-of-type(1) {
    transform-origin: top;
    transform-origin: bottom;
    align-self: flex-start;
    align-self: flex-end;
    width: 50%;
    transform: rotatez(45deg) translate(8px, 0px);
    transform: rotatez(45deg) translate(2.66666vw, 0px);
    transform: rotatez(45deg) translate(-5vw, 7.8vw);
    transform: rotatez(45deg) translate(-6vw, 7.8vw);
    transform: rotatez(45deg) translate(-3vw, 7.3vw);

    ${respond.pc.standard`
    transform: rotatez(45deg) translate(0.8vw, 0px);
    transform: rotatez(45deg) translate(-1.2vw, 2vw);
    `}
  }

  span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  span:nth-of-type(3) {
    transform-origin: bottom;
    transform-origin: top;
    width: 40%;
    width: 50%;
    align-self: flex-start;
    align-self: flex-end;
    transform: translate(30px, -11px) rotatez(45deg);
    transform: translate(10vw, -3.6666666vw) rotatez(45deg);
    transform: translate(0vw, -3.6666666vw) rotatez(45deg);
    transform: translate(-2vw, -5.1vw) rotatez(45deg);
    transform: translate(-1.5vw, -6vw) rotatez(45deg);
    transform: translate(0vw, -5vw) rotatez(45deg);

    ${respond.pc.standard`
    transform: translate(3vw, -1.1vw) rotatez(45deg);
      transform: translate(0vw, -1.6666666vw) rotatez(45deg);
    `}
  }
`;

const inputOpenBeforeStyles = css`
  &::before {
    background: #003153;
    background: ${rgba("white", 0.15)};
  }
`;

export const NavBurger = styled.label`
  ${respond.pc.standard`
    display: none;
  `};

  &:hover::before {
    background: #003153;
    background: rgba(125, 126, 127, 0.1);
  }

  &:focus::before {
    background: rgba(125, 126, 127, 0.1);
  }
  position: relative;

  &::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 120%;
    height: 120%;
    top: 0px;
    top: 50%;
    left: 0px;
    left: 55%;
    transition: 0.3s ease-out;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: transparent;
  }

  tranision: 0.2s ease-out;
  display: flex;
  flex-direction: column;

  width: 70px;
  width: 23vw;
  cursor: pointer;

  position: fixed;
  &:focus,
  &:active {
    border: none;
    outline: none;
  }
  top: 1vw;
  left: -2vw !important;
  z-index: 100;

  background: transparent;
  border: 0;

  ${respond.pc.standard`
    width: 7vw;
    top: 3vw;
    left: 2vw;  
    transform-origin: top;
    transform: scale(0.6);
  `}

  transform: scale(0.5);

  input[type="checkbox"] {
    display: none;
  }

  span {
    background: ${(p) => (p.open ? "white" : "#EB5E28")};
    background: ${(p) => (p.open ? "#102539" : "#EB5E28")};

    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6),
      background 0.5s ease-in-out;
    border-radius: 10px;
    border-radius: 1vw;
    height: 7px;
    height: 2.3333333vw;
    margin: 7px 0;
    margin: 2.3333333vw 0;

    ${respond.pc.standard`
      height: 0.7vw;
      margin: 0.7vw 0;
    `}
  }

  span:nth-of-type(1) {
    width: 50%;
    align-self: flex-end;
    width: 30%;
    transform: translate(0vw, 0) rotatez(0deg);
  }

  span:nth-of-type(2) {
    width: 10%;
    width: 100%;
    transform: translate(0vw, 0) rotatez(0deg);
  }

  span:nth-of-type(3) {
    width: 75%;
    width: 45%;
    align-self: flex-end;
    transform: translate(0vw, 0) rotatez(0deg);
  }

  &:hover span:nth-of-type(1) {
    transform: rotatez(-15deg);
  }
  &:hover span:nth-of-type(3) {
    transform: rotatez(15deg);
  }

  ${(p) => (p.open ? inputOpenStyles : noOpenStyles)};
  ${(p) => (p.open ? inputOpenBeforeStyles : noOpenStyles)};
`;

const noOpenStyles = css`
  &::before {
    background: transparent !important;
  }
  span:nth-of-type(1) {
    transform: translate(0vw, 0) rotatez(0deg) !important;
  }
  span:nth-of-type(3) {
    transform: translate(0vw, 0) rotatez(0deg) !important;
  }
`;

export const StyledLink = styled(Link)`
  display: inherit;
 

  &:hover ${NavIcon} {
    color: ${colors.accent.lightest};
  }
`;














































