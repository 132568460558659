//Framework
import React from 'react';
//State Mangement
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
//State Selector
import {
  selectMenuOpen,
  selectPopUpOpen,
} from "redux/global/global.selectors";
//Actions
import { toggleMenuOpen, togglePopUp } from "redux/global/global.actions";
//Styles
import {
  Overlay,
  Dialog,
  DialogContent,
  Header,
  Message,
  SubMessage,
  GridContainerWrapper,
  GridContainer,
  LocalLeft,
  LocalRight,
  LocalCenter,
  SubCard,
  Circle,
  ReadMore
} from "./slide-up-popup.styles";



export const SlideUpButtonJelly = ({ popUpOpen, togglePopUp }) => {
  return (
    <>
      <Overlay
        className="slide-up-overlay"
        isOpen={popUpOpen}
        onClick={togglePopUp}
      />
      <Dialog className="slide-up-dialog" isOpen={popUpOpen}>
        <DialogContent className="slide-up-dialog-content">
          <Header className="slide-up-header">
            <i
              style={{
                gridColumn: "3/4",
                borderRadius: "50%",
              }}
              className="material-icons closer"
              onClick={() => togglePopUp(false)}
            >
              close
            </i>
          </Header>

          <GridContainerWrapper className="grid-container-wrapper">
            <Message className="slide-up-message">
              No Waffles<span style={{ color: "#EB5E28", margin: 0 }}>.</span>
              <span className="slide-up-sub-message">
                <br />
                Just follow these steps
              </span>
            </Message>

            <GridContainer className="grid-container">
              <LocalLeft
                className="grid-local-left"
                style={{ marginBottom: "1rem" }}
                onClick={() => togglePopUp(false)}
                activeClass="active"
                to="youtube-player-tag2"
                spy={true}
                smooth={true}
                offset={-300}
                duration={1000}
              >
                <Circle backColor="#FFFFFF" delay="0s" duration="3s">
                  1
                </Circle>
                <SubCard>
                  <div>
                    <i
                      className="material-icons material-icons-outlined"
                    >
                      smart_display
                    </i>
                    <span>1minVideo</span>
                  </div>
                </SubCard>
              </LocalLeft>

              <LocalCenter
                className="grid-local-center"
                style={{ marginBottom: "1rem" }}
                rel="nofollow"
                href="https://www.vegeloper.com/resume.pdf"
                target="_newtab"
              >
                <Circle backColor="#FFFFFF" delay="0.2s" duration="3s">
                  2
                </Circle>
                <SubCard>
                  <div>
                    <i
                      className="material-icons material-icons-outlined"
                    >
                      file_download
                    </i>
                    <span>pdfResume</span>
                  </div>
                </SubCard>
              </LocalCenter>

              <LocalRight
                className="grid-local-right"
                to="/hiPal"
                onClick={() => togglePopUp(false)}
              >
                <Circle
                  className="local-right-circle"
                  backColor="#FFFFFF"
                  delay="0.4s"
                  duration="3s"
                >
                  3
                </Circle>
                <SubCard
                  className="local-right--sub"
                  style={{ marginBottom: "0rem" }}
                >
                  <div>
                    <i
                      className="material-icons material-icons-outlined"
                    >
                      near_me
                    </i>
                    <span>directMe</span>
                  </div>
                </SubCard>
              </LocalRight>
            </GridContainer>
            <ReadMore className="slide-up-read-more">
              <div>
                <SubMessage className="slide-up-message--sub">
                  Need more info?
                </SubMessage>
                <a
                  className="slide-up-read-more--link"
                  rel="nofollow"
                  target="_newtab"
                  href="https://www.linkedin.com/in/khashi-akhavan/"
                  onClick={() => togglePopUp(false)}
                >
                  Linkedin
                </a>
              </div>
            </ReadMore>
          </GridContainerWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
};


const mapStateToProps = createStructuredSelector({
  menuOpen: selectMenuOpen,
  popUpOpen: selectPopUpOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMenuOpen: () => dispatch(toggleMenuOpen()),
  togglePopUp: value => dispatch(togglePopUp(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SlideUpButtonJelly);












































// <ScrollContainer>
//   <Child>
//     <h1>CSS-only horizontal scroll tentative</h1>
//     <p>
//       The idea is to create an horizontal scroll layout and to allow the user to
//       scroll up/down the mouse to scroll left/right.
//     </p>
//     <p>
//       So… please scroll <strong>down</strong> with your mouse.
//     </p>
//   </Child>
//   <Child>
//     <h1>CSS-only horizontal scroll tentative</h1>
//     <p>
//       The idea is to create an horizontal scroll layout and to allow the user to
//       scroll up/down the mouse to scroll left/right.
//     </p>
//     <p>
//       So… please scroll <strong>down</strong> with your mouse.
//     </p>
//   </Child>
//   <Child>
//     <h1>CSS-only horizontal scroll tentative</h1>
//     <p>
//       The idea is to create an horizontal scroll layout and to allow the user to
//       scroll up/down the mouse to scroll left/right.
//     </p>
//     <p>
//       So… please scroll <strong>down</strong> with your mouse.
//     </p>
//   </Child>
//   <Child>
//     <h1>CSS-only horizontal scroll tentative</h1>
//     <p>
//       The idea is to create an horizontal scroll layout and to allow the user to
//       scroll up/down the mouse to scroll left/right.
//     </p>
//     <p>
//       So… please scroll <strong>down</strong> with your mouse.
//     </p>
//   </Child>
// </ScrollContainer>;