//Libraries
import styled, {keyframes} from "styled-components";
//Responsive
import respond from "design/responsive";
//Containers
import {VegeloperContainer} from "design/containers";
//Design
import colors from "design/colors";
import shadows from "design/shadows";
import fonts from 'design/fonts'; 


//Code
export const SkillsContainer = styled(VegeloperContainer)`
  //Container
  grid-column: cover-start / cover-end;
  background: transparent;
  justify-items: center;

  margin-right: auto;
  margin-left: auto;

  position: relative;

  &::selection {
    background: #e0ebe8;
    color: #545454;
    text-shadow: none;
  }
`;

export const ColMd6 = styled.div`
  position: relative;
  width: 100%;
  flex-flow: no-wrap;
  display: flex;
  justify-content: center;
  min-height: 1px;
`;

export const Icons = styled.div`
  display: flex;
  justify-content: center;

  ${respond.pc.standard`
  justify-content: flex-end;
  `}
`;

export const DesignRow = styled.div`
  grid-column: center-start/center-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  
  width: 100%;
  justify-self: center;
  align-self: center;

  & ${ColMd6}:not(:last-child) {
    margin-bottom: 15vw;   
    
    ${respond.pc.standard`
        margin-bottom: 5vw;
        transform: scale(1);
    `}
  }
}
`;

export const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 20%;
  position: relative;
  height: 100%;
`;

export const BubbleRight = styled.div`
  ${shadows.mixins.neumorphic.button};
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 15vw;
  height: 15vw;

  ${respond.pc.standard`
  width: 6vw;
  height: 6vw;
  `}
`;

export const Deets = styled.div`
  position: relative;
  justify-content: center;
  width: 100%;

  ${respond.pc.standard`
      width: 35vw;
    ;`}

  h3 {
    ${fonts.heading.h2};
    font-size: 7vw;
    line-height: 1;
    margin: 0;

    ${respond.tablet.standard`
     font-size: 6vw;
    ;`}
    ${respond.pc.standard`
      ${'' /* font-size: clamp(2.8rem, 2.8vw, 2.8vw); */}
     font-size: 2.8vw;

    ;`}
  }
`;

export const Text = styled.span`
  display: block;
  width: 100%;

  color: ${colors.neutrals.darkest};
  letter-spacing: 0px;
  font-style: normal;
  overflow-wrap: break-word;
  word-break: break-word;
  text-rendering: optimizeLegibility;

  line-height: 1.6;
  font-family: "Lato", sans-serif;
  font-family: "Open Sans", sans-serif;
  font-family: "Open Sans Condensed", sans-serif;
  font-family: "EB Garamond", serif;
  font-family: "Cabin", sans-serif;
  font-family: "Roboto Slab", serif;
  font-family: "Roboto Condensed", sans-serif;
  font-family: "Poppins", sans-serif;

  p {
    margin: 1vw 0 1vw;
    width: min-content;
    
    position: relative;
    font-family: inherit;
    
    font-size: 4vw;
  }

  font-size: 2vw;

  font-weight: 200;
  
  ${respond.tablet.standard`
    p {
   
    font-size: 3.5vw;
  }
  `}
  
  ${respond.pc.standard`
    padding-left: clamp(2px, 0.2vw, 0.2vw);
    font-size: 2vw !important;
    line-height: clamp(30px, 3vw, 3vw);  

    p {
      font-size: 1.5vw;
    }
    

  `}

  .love {
    margin-top: 3rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
 
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2vw;
  height: 15vw !important;

  ${respond.pc.standard`
    height: 7vw !important;
    margin-bottom: 1vw;
  `}
`;

export const Description = styled.p`
  position: relative;   
  margin: 4vw 0 4vw;
  padding-left: 0;
  width: 35vw;
  width: 100%;
  
  font-family: "Poppins", sans-serif;
  font-size: 3.4vw;
  font-weight: 200;
  text-align: left;   

  ${respond.tablet.standard`
    font-size: 3vw;
  `}
  ${respond.pc.standard`
    font-size: 1.3vw !important;
    margin: 0vw 0 1vw;
    text-align:left;  
  `}
}
`;

export const TitleBlock = styled.div`
  display: flex;
  flex: 80%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: clamp(114px, 38vw, 38vw);
  height: min-content;
  line-height: 1.6;
  height: 100%;

  ${respond.pc.standard`
  height: clamp(114px, 11.4vw, 11.4vw);
  height: min-content;
  height: 100%;
  `}
`;

const movePicture2 = keyframes`
   0% {
     transform: translate(0, -38%)  scale(1);
    }
    30% {
      transform: translate(0, -12.5%) scale(1);
    }
    60% {
      transform: translate(0, 12.5%) scale(1);
    }
    90% {
       transform: translate(0, 38%) scale(1);
    }
    90% {
       transform: translate(0, 38%) scale(1);
    }
    100% {
       transform: translate(0, -38%)  scale(1);
    }
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 85/ 1108;
  border: 0;
  transform-origin: top center !important;
  animation: ${movePicture2} 10s cubic-bezier(1, 0, 0, 1) infinite; // steps(50)


`;

