//Baiscs
import React , {useState} from 'react';
//Libraries
import Lottie from "react-lottie-light";
import {Waypoint} from 'react-waypoint';
//Design
import {
  LottieContainer,
  HeroHeaderRight,
} from "./hero-header-right.styles";
//Assets
import smile from 'assets/Lottie/smile.json';

//Code
export const HeroHeaderRightContainer = () => {
  let [isPaused, setIsPaused] = useState(false);
  let [isStopped, setIsStopped] = useState(false);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    hover: true,
    animationData: smile,
    rendererSettings: {
      preserveAspectRatio: "xMidYMax slice",
      progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
      hideOnTransparent: true,
      className: "css-blob",
      id: "thisIsReallyBad",
    },
  };
 
 return (
   <HeroHeaderRight>
     <LottieContainer className="lottieContainer">
       <Waypoint
         bottomOffset="-100%"
         onEnter={() =>
           setTimeout(() => {
             setIsPaused(false);
           }, 1000)
         }
         onLeave={() =>
           setTimeout(() => {
             setIsPaused(true);
           }, 1000)
         }
       />
       <Lottie
         className="lottieBlob"
         options={defaultOptions}
         height={"80%"}
         width={"100%"}
         isStopped={isStopped}
         isPaused={isPaused}
         style={{
           borderRadius: "inherit",
         }}
       />
     </LottieContainer>
   </HeroHeaderRight>
 );
};


export default (HeroHeaderRightContainer);