//Libraries
import { css } from "styled-components";
//Design
import colors from 'design/colors';

//Code
const shadows = {
  mixins: {
    neumorphic: {
      onActive_orange: (...args) => css`
        box-shadow: ${({ variant }) =>
          variant === "active"
            ? shadows.neumorphic.orange
            : shadows.neumorphic.norm};
      `,
      orange: (...args) => css`
        box-shadow: ${shadows.neumorphic.orange};
      `,
      norm: (...args) => css`
        box-shadow: ${shadows.neumorphic.norm};
      `,
      light: (...args) => css`
        box-shadow: ${shadows.neumorphic.light};
      `,
      red: (...args) => css`
        box-shadow: ${shadows.neumorphic.red};
      `,
      original: (...args) => css`
        box-shadow: ${shadows.neumorphic.original};
      `,
      invert: (...args) => css`
        box-shadow: ${shadows.neumorphic.invert};
      `,
      extra: (...args) => css`
        box-shadow: ${shadows.neumorphic.extra};
      `,
      button: (...args) => css`
        box-shadow: ${shadows.neumorphic.button};
      `,
    },
  },
  neumorphic: {
    lightest: "rgba(255, 255, 255, 0.93)",
    lighter: " rgba(125, 126, 127, 0.4)",
    light: `0rem 0rem 1.5rem .2rem ${colors.shadows.light},
          -.1rem -.1rem 1.2rem .2rem ${colors.highlights.lighter}`,
    dark: `.4rem .4rem 1.5rem .2rem ${colors.greyDark}`,
    norm: `.4rem .4rem 1.0rem 0rem ${colors.shadows.lighter},
          -.3rem -.3rem 1.0rem 0rem ${colors.highlights.lightest}`,
    orange: `.5rem .5rem 1.5rem 0rem  ${colors.shadows.dark},
           -.4rem -.3rem .4rem 0rem ${colors.neutrals.lightest}, inset 0rem -0.6vw 0rem 0rem ${colors.accent.lightest}`,
    orangeOld: `.3rem .3rem .5rem 0rem  ${colors.shadows.dark},
           -.3rem -.3rem .4rem 0rem ${colors.neutrals.lightest},
          inset 0rem -.4rem 0rem 0rem ${colors.accent.lightest}`,
    orange2: `.5rem .5rem 1.5rem 0rem  ${colors.shadows.dark},
           -.4rem -.3rem .4rem 0rem ${colors.neutrals.lightest}, inset 0rem -0.8vw 0rem 0rem ${colors.accent.lightest}`,
    orange3: `.4rem .4rem 1.5rem .2rem ${colors.shadows.lighter},
          -.3rem -.3rem 1.2rem .2rem ${colors.highlights.lightest},
           0rem .4rem 2.0rem .5rem ${colors.accent}`,
    red: `.4rem .4rem 1.5rem .2rem ${colors.redLight},
          -.3rem -.3rem 1.2rem .2rem ${colors.redDark}`,
    original: `0rem 0.2rem 0.5rem 0rem  ${colors.shadows.dark},
           -0.4rem -0.3rem 0.4rem 0rem ${colors.neutrals.lightest}`,
    originalOrange: `0rem .2rem .5rem 0rem  ${colors.shadows.dark},
           -.4rem -.3rem .4rem 0rem ${colors.neutrals.lightest}, inset 0rem -0.8vw 0rem 0rem ${colors.accent.lightest}`,
    extra: `.4rem .4rem 1rem 0rem  ${colors.shadows.dark},
           -.4rem -.4rem 1rem 0rem ${colors.highlights.lightest}`,
    invert: `.4rem .4rem 1rem 0rem inset  ${colors.shadows.lighter},
           -.3rem -.3rem 1rem .2rem inset ${colors.highlights.lightest}`,
    button: `.4rem .4rem 1rem 0rem   ${colors.shadows.lighter},
           -.3rem -.3rem 1rem 0rem  white`,
    buttonHover: `
    .4rem .4rem 1.5rem .2rem ${colors.shadows.lighter},
           -.3rem -.3rem 1.2rem .2rem ${colors.highlights.lightest}; 
    `,
  },
};

export default shadows;