//Framework
import React from "react";
//Error Handling
import ErrorBoundarySimple from "components/error-boundary-simple/error-boundary-simple.component";
//Router
import RouterSwitch from "routes/router-switch";
//Styles
import GlobalStyle from "design/global.styles";


const App = ({ props }) => {

  return (
    <>
      <GlobalStyle />
      <ErrorBoundarySimple>
      <RouterSwitch {...props} />
      </ErrorBoundarySimple>
    </>
  );
};



export default App;