//Baiscs
import React from 'react';
//State Management
import { connect } from 'react-redux';
//Actions
import {togglePopUp} from "redux/global/global.actions";
//Assets
import {SvgIcon} from './hero-header-left.styles';
//Design
import { Accent_FireOrange } from "design/effects";
import {
  Button,
  BackgroundFigure,
  TEXT,
  Heading,
  HeroHeaderLeft,
  SmallMargin,
} from "./hero-header-left.styles";


//Code
export const HeroHeaderLeftContainer = ({ togglePopUp }) => {
  return (
    <HeroHeaderLeft>
      <Heading>
        <p className="hi">
          <SmallMargin>Hi</SmallMargin>
          <Accent_FireOrange>.</Accent_FireOrange>
          <br />
          I'm
          <br /> <Accent_FireOrange>K</Accent_FireOrange>hashi
        </p>
      </Heading>

      <TEXT>
        <p style={{ display: "inline" }}>
          a web <span style={{ fontWeight: 400 }}>developer</span>
        </p>
      </TEXT>

      <Button onClick={togglePopUp}>
        <p>Pleased to meet you</p> 
        <SvgIcon />
        {/* <br /> */}
        <span>tea or coffee ? </span>
        <BackgroundFigure></BackgroundFigure>
      </Button>
    </HeroHeaderLeft>
  );
};


const mapDispatchToProps = (dispatch) => ({
  togglePopUp: () => dispatch(togglePopUp()),
});

export default connect(
  null,
  mapDispatchToProps
)(HeroHeaderLeftContainer);
