//State Management
import { all, call, put, takeLatest } from 'redux-saga/effects';
//Libraries
import headlessCMS from 'headless-cms/contentful/contentful.setup';
// import headlessCMS from 'headless-cms/contentful/contentful.utils';

//Actions
import {
  fetchContentFailure,
  fetchContentSuccess, fetchSingleArticleFailure,
  fetchSingleArticleSuccess
} from "./content.actions";
//Types
import ContentActionTypes from './content.types';

//Const
const { log } = console;


//Code
export function* fetchContentAsync({hasData}) {
  if (hasData !== true) {
     try {
    const data = yield headlessCMS.getEntries({content_type: 'post'});
    const posts = data.items; // location of posts inside JSON data received from Contentful API.
    yield put(fetchContentSuccess(data));
  } catch (error) {
    yield put(fetchContentFailure(error.message));
  }
  }
  else {
    
  }
}

export function* fetchSingleArticleAsync({payload: slug}) {
         try {
           const data = yield headlessCMS.getEntries({
             content_type: "post",
             "fields.slug": slug,
           });
           const post = data.items[0];
          
          yield put(fetchSingleArticleSuccess(post));
          //  yield put(fetchSingleArticleSuccess(post));
         } catch (error) {
           yield put(fetchSingleArticleFailure(error.message));
         }
       }

export function* onfetchContentStart() {
  yield takeLatest(
    ContentActionTypes.FETCH_CONTENT_START,
    fetchContentAsync
  );
}

export function* onfetchSingleArticleStart() {
  yield takeLatest(
    ContentActionTypes.FETCH_ARTICLE_START,
    fetchSingleArticleAsync
  );
}

export function* contentSagas() {
  yield all([
    call(onfetchContentStart),
    call(onfetchSingleArticleStart)
  ]);
}
