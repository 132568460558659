//Libraries
import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
// import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
//State Management
import rootReducer from 'redux/root-reducer';
import rootSaga from 'redux/root-saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

// if (process.env.NODE_ENV === 'development') {
//   middlewares.push(logger);
// }

export const store = createStore(rootReducer, applyMiddleware(...middlewares));

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default { store, persistStore };
