//Libraries
import { all, call } from 'redux-saga/effects';
//State Management
import { globalSagas } from 'redux/global/global.sagas';
import { contentSagas } from "redux/content/content.sagas";
import { messageMeSagas } from "redux/message-me/message-me.sagas";


export default function* rootSaga() {
  yield all([
    call(globalSagas),
    call(contentSagas), 
    call(messageMeSagas), 
  ]);
}

