//Framework
import React from 'react';
//State Management
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
//State Selector
import {
  selectMenuOpen,
  selectPopUpOpen,
} from "redux/global/global.selectors";
//Actions
import {
  closeMenu,
  toggleMenuOpen,
  togglePopUp,
} from "redux/global/global.actions";
//Containers
import { ViewPort, ContentView } from "design/containers";
//Components
import  ThisContent from './home.content';
import {ModernNav} from 'components/modern-nav/modern-nav.component';
import { SlideUpButtonJelly } from "components/slide-up-popup/slide-up-popup.component";
import { Overlay } from './home.styles';

//Code
const HomePage = (props) => {
  const { menuOpen, toggleMenuOpen, popUpOpen, togglePopUp, closeMenu} = props;
  return (
    <ViewPort>
      <ModernNav open={menuOpen} toggleMenuOpen={toggleMenuOpen} />
      <SlideUpButtonJelly popUpOpen={popUpOpen} togglePopUp={togglePopUp} />
      {/* #VISIBLE CONTENT */}
      <ContentView open={menuOpen}>
        <Overlay onClick={closeMenu} open={menuOpen} />
        <ThisContent {...props} closeMenu={closeMenu} />
      </ContentView>
    </ViewPort>
  );
};

const mapStateToProps = createStructuredSelector({
  menuOpen: selectMenuOpen,
  popUpOpen: selectPopUpOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleMenuOpen: () => dispatch(toggleMenuOpen()),
  closeMenu: value => dispatch(closeMenu(value)),
  togglePopUp: value => dispatch(togglePopUp(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);