//Libraries
import styled from "styled-components";
import {rgba} from 'polished';
//Containers
import {VegeloperContainer} from 'design/containers';


//Code
export const InnerGrid = styled(VegeloperContainer)`
  position: relative;
  background-color: transparent;
`;



const duration = "0.5s";

export const Overlay = styled.div`
  position: absolute;
  z-index: 10000000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba("black", 0.5)};
  background: transparent;
  visibility: ${(p) => (p.open ? "visible" : "hidden")};
  display: ${(p) => (p.open ? "auto" : "none")};
  opacity: ${(p) => (p.open ? 1 : 0)};
  transition-property: visibility opacity;
  transition-duration: ${duration};
`;
