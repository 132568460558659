//Libraries
import {css} from 'styled-components';
//Design
import respond from 'design/responsive';

//Code
const fonts = {
  menu: {
    logo: (...args) => css`
      font-family: "Barriecito", cursive;
      font-weight: 400;
      font-size: clamp(30px, 3vw, 3vw);
      line-height: 1.6;
    `,
    link: (...args) => css`
      font-family: Montserrat, sans-serif;
      font-weight: 800;
      font-size: clamp(11px, 1.1vw, 1.1vw);
      line-height: 1.6;
    `,
  },

  heading: {
    h1: (...args) => css`
      font-family: "Rubik Mono One", Cambria, "Times New Roman", Times,
        sans-serif;

      font-weight: 400;
      font-size: clamp(3.9rem, 3.80859375vw, 3.80859375vw);
      line-height: 1;
    `,
    h1_sub: (...args) => css`
      font-family: "Poppins", sans-serif;

      font-size: 2vw;
      font-weight: 400;
      line-height: clamp(30px, 2.9296vw, 2.9296vw);
    `,
    h1_sub_light: (...args) => css`
      font-family: "Poppins", sans-serif;

      font-size: 2vw;
      font-weight: 200;
      line-height: clamp(30px, 2.9296vw, 2.9296vw);
    `,
    h1_button: (...args) => css`
      font-family: "Rubik Mono One", Cambria, "Times New Roman", Times,
        sans-serif;
      font-weight: 400;

      font-size: clamp(0.9rem, 0.8789vw, 0.8789vw);
      line-height: 1.6;
    `,
    h1_button_sub: (...args) => css`
      font-family: "Poppins", sans-serif;

      font-size: clamp(0.9rem, 0.8789vw, 0.8789vw);
      font-weight: 200;
      line-height: 1.6;
    `,
    level2: {
      default: (...args) => css`
        font-family: "Rubik Mono One", Cambria, "Times New Roman", Times,
          sans-serif;

        font-size: 4vw;
        font-weight: 400;
        line-height: 1;

        ${respond.tablet.standard`
        font-size: 3.8vw;
      `}

        ${respond.pc.standard`
        font-size: 2.5vw;
      `}
      `,
      sub: (...args) => css`
        font-family: "Nunito", sans-serif;
        font-size: 4vw;
        font-weight: 400;
        line-height: 1;

        ${respond.tablet.standard`
        font-size: 3.7vw;
      `}

        ${respond.pc.standard`
          font-size: 2vw;
           line-height: clamp(30px, 3vw, 3vw);
      `}
      `,
      sub_light: (...args) => css`
        font-family: "Nunito", sans-serif;

        font-size: 5vw;
        font-weight: 200;
        line-height: 5vw;

        ${respond.pc.standard`
          font-size: 2vw;
          line-height: clamp(30px, 3vw, 3vw);
        `}
      `,
      text: (...args) => css`
        font-family: "Nunito", sans-serif;

        font-size: 3.25vw;
        font-size: 3vw;
        font-weight: 200;
        line-height: 1.6;
        line-height: 1.4;

        ${respond.pc.standard`
          font-size: 1.5vw;
        `}
      `,
    },
    level3: {
      default: (...args) => css`
        font-family: "Rubik Mono One", Cambria, "Times New Roman", Times,
          sans-serif;
        font-size: clamp(28px, 2.8vw, 2.8vw);
        font-size: 8.4vw;
        font-weight: 400;
        line-height: 1;

        ${respond.pc.standard`
        font-size: 2.8vw;
      `}
      `,
      sub: (...args) => css`
        font-family: "Nunito", sans-serif;
        font-size: clamp(20px, 2vw, 2vw);
        font-size: 6vw;
        font-weight: 400;
        line-height: clamp(30px, 3vw, 3vw);

        ${respond.pc.standard`
          font-size: 2vw;
          line-height: 10vw;
      `}
      `,
      sub_light: (...args) => css`
        font-family: "Nunito", sans-serif;
        font-size: 6vw;
        font-weight: 200;
        line-height: clamp(30px, 3vw, 3vw);

        ${respond.pc.standard`
          font-size: 2vw;
          line-height: 10vw;
        `}
      `,
      text: (...args) => css`
        font-family: "Poppins", sans-serif;
        font-size: 3.3vw;
        font-size: 2.75vw;
        font-weight: 800;
        line-height: 1.6;

        ${respond.pc.standard`
          font-size: 1.1vw;
        `}
      `,
    },
    level6: {
      default: (...args) => css`
        font-family: "Nunito", sans-serif;
        font-size: clamp(20px, 2vw, 2vw);
        font-size: 6vw;
        font-weight: 400;
        line-height: clamp(30px, 3vw, 3vw);

        ${respond.pc.standard`
          font-size: 2vw;
          line-height: 10vw;
        `}
      `,
      subCode: (...args) => css`
        font-family: "Nunito", sans-serif;
        font-size: 2vw;
        font-weight: 400;
        line-height: 1;

        ${respond.pc.standard`
          font-size: 1.3vw;
          line-height: 1;
        `}
      `,
    },
    footer: {
      default: (...args) => css`
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        font-size: clamp(20px, 2vw, 2vw);
        font-size: 6vw;
        line-height: clamp(30px, 3vw, 3vw);

        ${respond.pc.standard`
          font-size: 2vw;
          line-height: 10vw;
        `}
      `,
      subCode: (...args) => css`
        font-family: "Nunito", sans-serif;
        font-size: 2vw;
        font-weight: 400;
        line-height: 1;

        ${respond.pc.standard`
          font-size: 1.3vw;
          line-height: 1;
        `}
      `,
    },
    h2: (...args) => css`
      font-family: "Rubik Mono One", Cambria, "Times New Roman", Times,
        sans-serif;
      font-size: clamp(28px, 2.8vw, 2.8vw);
      font-size: 8.4vw;
      font-weight: 400;
      line-height: 1;

      ${respond.pc.standard`
        font-size: 2.8vw;
      `}
    `,
    h2_sub: (...args) => css`
      font-family: "Nunito", sans-serif;
      font-size: clamp(20px, 2vw, 2vw);
      font-size: 6vw;
      font-weight: 400;
      line-height: clamp(30px, 3vw, 3vw);

      ${respond.pc.standard`
        font-size: 2vw;
        line-height: 10vw;
      `}
    `,
    h2_sub_light: (...args) => css`
      font-family: "Nunito", sans-serif;
      font-size: 6vw;
      font-weight: 200;
      line-height: clamp(30px, 3vw, 3vw);

      ${respond.pc.standard`
        font-size: 2vw;
        line-height: 10vw;
      `}
    `,
    h2_text: (...args) => css`
      font-family: "Nunito", sans-serif;
      font-size: clamp(17px, 1.7vw, 1.7vw);
      font-size: 5.1vw;
      font-weight: 200;
      line-height: 1.6;

      ${respond.pc.standard`
        font-size: 1.7vw;
      `}
    `,
    h3: (...args) => css`
      font-family: "Rubik Mono One", Cambria, "Times New Roman", Times,
        sans-serif;
      line-height: 1.5;
      font-weight: 700;
      font-size: 1.5vw;
      -webkit-font-smoothing: antialiased;
    `,
    h3_text: (...args) => css`
      font-family: "Poppins", sans-serif;
      font-size: 3.3vw;
      font-weight: 800;
      line-height: 1.6;

      ${respond.pc.standard`
        font-size: 1.1vw;
      `}
    `,
    h3_button: (...args) => css`
      font-family: Arial;
      font-size: 1vw;
      font-weight: 400;
      font-stretch: normal;
      line-height: normal;
    `,
    h4: (...args) => css`
      font-family: "Rubik Mono One", Cambria, "Times New Roman", Times,
        sans-serif;
      line-height: 1.5;
      font-weight: 700;
      font-size: 1.46484375vw;
      -webkit-font-smoothing: antialiased;
    `,
    h4_text: (...args) => css`
      font-family: "Poppins", sans-serif;
      font-size: 0.9765625vw;
      font-weight: 200;
      line-height: 1.6;
    `,
  },

  mixins: {
    CTA: {
      visitProfile: (...args) => css`
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 1.6rem;
      `,
      callMe: (...args) => css`
        font-family: "Barriecito", cursive;
        font-size: 3.2rem;
        font-weight: 400;
      `,
      emailMe: (...args) => css`
        font-family: "Barriecito", cursive;
        font-size: 3.2rem;
        font-weight: 400;
      `,
    },
    logo: (...args) => css`
      font-family: "Barriecito", cursive;
      font-size: 3.2rem;
      font-weight: 400;
    `,
    menuButton: (...args) => css`
      font-family: "Montserrat", sans-serif;
      font-size: 1.2rem;
      font-weight: 800;
    `,
    text: (...args) => css`
      font-family: "Poppins", sans-serif;
      font-size: 4.2vw;
      font-weight: 200;
    `,
    heroHeader: (...args) => css`
      font-family: "Rubik Mono One", Cambria, "Times New Roman", Times,
        sans-serif;
      font-weight: 400;
    `,
  },
};

export default (fonts);