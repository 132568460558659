// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );


// reportWebVitals();


//Franework
import React from 'react';
import ReactDOM from 'react-dom';
//Libraries
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
//State Management
import { store, persistor } from "./redux/store";
//Components
import App from './App';
//Analytics
import reportWebVitals from './reportWebVitals';
//PWA
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";


ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);


reportWebVitals();

//Install PWA
serviceWorkerRegistration.register();
