//State Managemenet
import { takeLatest, put, all, call } from 'redux-saga/effects';
//Libraries
import axios from 'axios';
//Actions
import {
  sendClientRichTextMessageToServerSuccess, 
  sendClientRichTextMessageToServerFailure,
} from "./message-me.actions";
//Types
import MessageMeActionTypes from "./message-me.types";


//Code
export function* CallToAPI({ payload: { URL, data }}) {
  try {
    const response = yield axios.post(URL, {data: data});
    yield put(sendClientRichTextMessageToServerSuccess(true));
  } catch (error) {
    yield put(sendClientRichTextMessageToServerFailure(true));
  }
}

   

export function* onSendDataToServer() {
  yield takeLatest(MessageMeActionTypes.SEND_DATA_SERVER_START, CallToAPI);
}

export function* messageMeSagas() {
  yield all([
    call(onSendDataToServer)
  ]);
}

