//Libraries
import { keyframes } from "styled-components";

//Code
export const bubbleGumScale = keyframes`
  
  0%{
    transform: ScaleY(1);
    border-top-right-radius: 130%;
    border-bottom-right-radius: 150%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;
  }
  
  
  15%{
    transform: ScaleY(1.160714);


    border-top-right-radius: 120%;
    border-top-left-radius: 135%;
    border-bottom-left-radius: 115%;
    border-bottom-right-radius: 150%;


  }
  
  30%{
    transform: ScaleY(1.071428);
    border-top-right-radius: 130%;
    border-top-left-radius: 195%;
    border-bottom-left-radius: 145%;
    border-bottom-right-radius: 200%;
  }

  
  45% {
    transform: ScaleY(1.0892857);
    border-top-right-radius: 135%;
    border-top-left-radius: 145%;  
    border-bottom-left-radius: 165%;
    border-bottom-right-radius: 180%;

  }

  60% {
    transform: ScaleY(1.2321428);

    border-top-right-radius: 160%;
    border-bottom-right-radius: 155%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;
  }

  75% {
    transform: ScaleY(1.3392857);

    border-top-left-radius: 180%;
    border-top-right-radius: 160%;
    border-bottom-right-radius: 155%;
    border-bottom-left-radius: 140%;
    
    
  }

  100%{
    transform: ScaleY(1);


    border-top-right-radius: 130%;
    border-bottom-right-radius: 150%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;

    
    
  }
  
`;

export const bubbleGum = keyframes`
  
  0%{
    transform: ScaleY(1);
    
    border-top-right-radius: 130%;
    border-bottom-right-radius: 150%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;
  }
  
  
  15%{
    transform: ScaleY(1.05);

    border-top-right-radius: 120%;
    border-top-left-radius: 135%;
    border-bottom-left-radius: 115%;
    border-bottom-right-radius: 150%;


  }
  
  30%{
    transform: ScaleY(1.1);
    border-top-right-radius: 130%;
    border-top-left-radius: 195%;
    border-bottom-left-radius: 145%;
    border-bottom-right-radius: 200%;
  }

  
  45% {
    transform: ScaleY(1.15);
    border-top-right-radius: 135%;
    border-top-left-radius: 145%;  
    border-bottom-left-radius: 165%;
    border-bottom-right-radius: 180%;

  }

  60% {
    transform: ScaleY(1.1);

    border-top-right-radius: 160%;
    border-bottom-right-radius: 155%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;
  }

  75% {
    transform: ScaleY(1.05);

    border-top-left-radius: 180%;
    border-top-right-radius: 160%;
    border-bottom-right-radius: 155%;
    border-bottom-left-radius: 140%;
    
    
  }

  100%{
    transform: ScaleY(1);
    border-top-right-radius: 130%;
    border-bottom-right-radius: 150%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;

    
    
  }
`;

export const bubbleGumDesktop = keyframes`
  
  0%{
    transform: ScaleY(1);
    border-top-right-radius: 130%;
    border-bottom-right-radius: 150%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;
  }
  
  
  15%{
    transform: ScaleY(1.05);
    border-top-right-radius: 120%;
    border-top-left-radius: 135%;
    border-bottom-left-radius: 115%;
    border-bottom-right-radius: 150%;


  }
  
  30%{
    transform: ScaleY(1.1);
    border-top-right-radius: 130%;
    border-top-left-radius: 195%;
    border-bottom-left-radius: 145%;
    border-bottom-right-radius: 200%;
  }

  
  45% {
    transform: ScaleY(1.15);
    border-top-right-radius: 135%;
    border-top-left-radius: 145%;  
    border-bottom-left-radius: 165%;
    border-bottom-right-radius: 180%;

  }

  60% {
    transform: ScaleY(1.1);
    border-top-right-radius: 160%;
    border-bottom-right-radius: 155%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;
  }

  75% {
    transform: ScaleY(1.05);
    border-top-left-radius: 180%;
    border-top-right-radius: 160%;
    border-bottom-right-radius: 155%;
    border-bottom-left-radius: 140%;
    
    
  }

  100%{
    transform: ScaleY(1);
    border-top-right-radius: 130%;
    border-bottom-right-radius: 150%;
    border-top-left-radius: 180%;
    border-bottom-left-radius: 140%;
  }
`;
