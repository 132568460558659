//Libraries
import styled from "styled-components";
//Responsive
import respond from 'design/responsive';
//Design
import colors from 'design/colors';


export const Accent_FireOrange = styled.span`
  color: #eb5e28;
`;

export const Highlighter = styled.div`
  position: relative;
  width: min-content;
  z-index: 20;

  &::after {
    position: absolute;
    content: "";
    z-index: -1;
    left: 0;
    bottom: 0;
    bottom: -2px;
    width: 100%;
    height: 1.5vw;
    background: ${colors.accent.ochreFire};
    background: ${colors.accent.darkest};
    background: ${colors.accent.lightest};
    transform-origin: 0% 100%;
    transition: transform 0.25s;

    ${respond.pc.standard`
    height: 0.6vw;
    `}
  }

  &:hover::after {
    transform: scaleY(2.5);
  }
`;




