//Framework
import React from 'react';
//Components
import TabbedCardNeumorphic from 'components/tabbed-card-neumorphic/tabbed-card-neumorphic.component';
//Design
import { Highlighter } from 'design/effects';
import {
  SvgIcon,
  Icon_cognitiveScience,
  Icon_cyberSecurity,
  CardScroll,
  Header3,
} from "./card-scroll.styles";

//Code
const Template = (props) => {
  const { project, projectSub, photo, techOne, techOneIcons, techTwo, techTwoIcons, goal, challenge} = props;
  
  const tabbedCardProps = {
    techOne, techOneIcons, techTwo, techTwoIcons , goal, challenge
  };

  return (
    <CardScroll>
      {photo === "react" ? <SvgIcon /> : photo==="cognitive" ? <Icon_cognitiveScience /> : <Icon_cyberSecurity />}

      <Header3 >
        {project} <Highlighter> {projectSub}</Highlighter>
      </Header3>   
      <TabbedCardNeumorphic {...tabbedCardProps} />
    </CardScroll>
  );
};

export default Template;
