const GlobalActionTypes = {
  TOGGLE_DARK_MODE: "TOGGLE_DARK_MODE",
  CLOSE_MENU: "CLOSE_MENU",
  TOGGLE_POP_UP: "TOGGLE_POP_UP",
  TOGGLE_MENU_OPEN: "TOGGLE_MENU_OPEN",
  EMAIL_RICH_TEXT: "EMAIL_RICH_TEXT",
  SEND_DATA_SERVER_START: "SEND_DATA_SERVER_START",
  SEND_OBJECT_TO_SERVER_START: "SEND_OBJECT_TO_SERVER_START",
};

export default GlobalActionTypes;
