//Libraries
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
//State Management
import globalReducer from 'redux/global/global.reducer';
import messageMeReducer from 'redux/message-me/message-me.reducer';
import contentReducer from "redux/content/content.reducer";


const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['content']
};

const rootReducer = combineReducers({
  global: globalReducer,
  messageMe: messageMeReducer,
  content: contentReducer,
});


export default persistReducer(persistConfig, rootReducer);
