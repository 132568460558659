//Types
import GlobalPageActionTypes from "redux/global/global.types";


export const toggleMenuOpen = (value) => ({
  type: GlobalPageActionTypes.TOGGLE_MENU_OPEN,
  payload: value,
});
export const closeMenu = (value) => ({
  type: GlobalPageActionTypes.CLOSE_MENU,
  payload: value,
});
// export const toggleMenuOpen = (value) => {
//   if (value === false) {
//     return {
//       type: GlobalPageActionTypes.TOGGLE_MENU_OPEN,
//       payload: value,
//     };
//   } else {
//      return {
//        type: GlobalPageActionTypes.TOGGLE_MENU_OPEN,
//      };
//   }
 
// };
export const togglePopUp = value => ({
  type: GlobalPageActionTypes.TOGGLE_POP_UP,
  payload: value,
});
export const emailRichText = value => ({
  type: GlobalPageActionTypes.EMAIL_RICH_TEXT,
  payload: value,
});
export const sendDataToServer = (URL, data) => ({
  type: GlobalPageActionTypes.SEND_DATA_SERVER_START,
  payload: {URL, data},
});
