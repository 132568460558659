//Framework
import React from 'react';
//Styles
import { SpinnerOverlay } from './fallback-spinner.styles';

//Code
const Spinner = (props) => {
  return (
    <SpinnerOverlay>
      <h1>Loading Spinner ...</h1>
    </SpinnerOverlay>
  );
}

export default Spinner;
