//Libraries
import styled from "styled-components";
//Responsive
import respond from 'design/responsive';
//Design
import colors from 'design/colors';


export const ScrollContainerFlex = styled.div`
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: ${colors.secondary.lightest};
    background-color: #eb5e28;
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      color-stop(0.5, rgba(255, 255, 255, 0.2)),
      color-stop(0.5, transparent),
      to(transparent)
    );
  }

  &:hover {
    scrollbar-color: #eb5e28 white;
  }
  &:drag {
    scrollbar-color: #eb5e28 white;
  }

 scrollbar-color: #eb5e28 white !important;
  scrollbar-width: thin; 
  width: 100vw;
  height: 100px;
  height: min-content;
  height: 100vw;

  grid-column: full-start/ full-end;
  grid-column: cover-start/ cover-end;
  font-size: 20px;

  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap; // Allow continuing into the white space.
  overflow: auto;

  margin-bottom: 10vw;

  ${respond.tablet.standard`
    height: 90vw;
      margin-bottom: 10vw;

  `}

  ${respond.pc.standard`
     margin-bottom: 3vw;
  height: min-content;
  grid-column: full-start/ full-end;
  width: 100%;
  padding: 5vw 1vw; 
 
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  `}
`;

export const ScrollCardContainer = styled.div`
  margin: 11.1111vw 5.555555vw;
  margin: 4rem 6rem 4rem 2rem;
  margin: 4rem 2rem 4rem 2rem;
  margin: 13.333333vw 6.6666666vw;
  margin: 0vw 6.6666666vw;

  ${respond.mobile.landscape`
    transform: scale(0.8);
  `}

  ${respond.tablet.standard`
    transform: scale(0.8);
  `}

  ${respond.pc.standard`
  transform: scale(1);
   margin: 4rem 2rem;
   margin: 3.90625vw 1.953125vw;
   margin: clamp(2rem,1.953125vw,1.953125vw);
  `}

  transition: all 0.8s ease;

  &:last-child {
    padding-right: clamp(5rem, 16.5555vw, 16.66666vw);
    padding-right: clamp(4rem, 12vw, 12vw);

    ${respond.pc.min`
    padding-right: 0;
    `}
  }

  &:first-child {
    margin-left: clamp(2.5rem, 8vw, 8vw);
    margin-left: clamp(4rem, 12vw, 12vw);

    & > * {
      background-color: ${colors.neutrals.lighter} !important;
    }

    ${respond.pc.min`
    margin: clamp(2rem,1.953125vw,1.953125vw);
    `}
  }
`;