//Framework
import React from 'react';
//Components
import {FrontEnd, BackEnd} from "components/flex-icons/flexIcons.component";
//Design
import {
  SkillsContainer,
  DesignRow,
  BubbleContainer,
  BubbleRight,
  Image,
  ColMd6,
  Deets,
  TitleContainer,
  TitleBlock,
  Description,
  Icons,
  Text,
  Wrapper,
} from "./skills.styles";


//Code
export const Header = (props) => {
  

  return (
    <SkillsContainer className="skills-container">
      <DesignRow className="DesignRow">
        <ColMd6 className="tech-stack-container">
          <Deets
            className="tech-stack-card"
            style={{ textAlign: "Right", marginTop: "2vw" }}
          >
            <TitleContainer className="titleContainer">
              <Wrapper>
                <BubbleContainer>
                  <BubbleRight>
                    <Image
                      className=""
                      height="auto"
                      width="100%"
                      src={`https://res.cloudinary.com/khashi-dev/image/upload/v1627277748/frontend-icons_qdqca0.svg`}
                      alt="Full Logo --frontend"
                    />
                  </BubbleRight>
                </BubbleContainer>
                <TitleBlock>
                  <h3 className="hi">Tech Stack</h3>
                  <Text>
                    <p style={{ display: "inline" }}>
                      <span style={{ fontWeight: 200 }}>modern,</span>{" "}
                      <span style={{ fontWeight: 400, color: "#EB5E28" }}>
                        smooth,
                      </span>{" "}
                      <span style={{ fontWeight: 200 }}> accessible</span>
                    </p>
                  </Text>
                </TitleBlock>
              </Wrapper>
            </TitleContainer>
            <Description>
              Ideally, it's the project that dictates the stack. <br />A
              developer's job is to identify its fundamental needs and use
              proper tools to serve them .
              <br />
              <p style={{ lineHeight: "0.5" }}>
                <br />
              </p>
              These days, I'm passionately following Language-Agnostic ideas and
              extending my toolset with hot and emerging technologies such as:
            </Description>
            <Icons className={"frontend__icons--container"}>
              <FrontEnd className={"frontend__icons--component"} />
            </Icons>
          </Deets>
        </ColMd6>
        <ColMd6 className="cloud-era-container">
          <Deets className="cloud-era-card">
            <TitleContainer className="titleContainer">
              <Wrapper>
                <TitleBlock style={{ alignItems: "flex-start" }}>
                  <h3 className="hi">Cloud Era </h3>{" "}
                  <Text>
                    <p style={{ display: "inline" }}>
                      <span style={{ fontWeight: 200 }}>scalable,</span>{" "}
                      <span style={{ fontWeight: 400, color: "#EB5E28" }}>
                        secure,
                      </span>{" "}
                      <span style={{ fontWeight: 200 }}> available</span>
                    </p>
                  </Text>
                </TitleBlock>
                <BubbleContainer>
                  <BubbleRight>
                    <Image
                      className=""
                      height="auto"
                      width="100%"
                      src="https://res.cloudinary.com/khashi-dev/image/upload/v1627277748/backend-icons_tbssat.svg"
                      alt="Full Logo --backend"
                    />
                  </BubbleRight>
                </BubbleContainer>
              </Wrapper>
            </TitleContainer>
            <Description>
              We are on the precipice of a golden age in cloud technologies.
              This marked shift will probably forever change the way we build
              and scale web apps.
              <br />
              <p style={{ lineHeight: "0.5" }}>
                <br />
              </p>
              Instant access and Secure interactions are the new normals.
              Naturally, I need to use a number of different tools and services
              to meet this ever-growing industry standard. I adore most of them,
              namely:
              <br />
            </Description>
            <Icons className={"backend__icons--container"}>
              <BackEnd className={"backend__icons--component"} />
            </Icons>
          </Deets>
        </ColMd6>
      </DesignRow>
    </SkillsContainer>
  );
};



export default Header;

