//Libraries
import styled from 'styled-components';
//Design
import colors from 'design/colors';
import {respond} from 'design/responsive';

export const SpinnerOverlay = styled.div`
  position: fixed;
  height: 100vh !important;
  width: 100%;
  padding: 0;
  margin: 0;

background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.h3`
  ${'' /* font-family: "Poppins", Arial, sans-serif; */}
  font-family: Cambria, "Times New Roman", Times, serif;
  color: orange;
  font-weight: bold;
  font-weight: 600;
  font-weight: 800;
  margin-left: -25%;
  margin-top: 25%;
  font-size: 1em;
  font-size: 2.7vw;

  ${respond.tablet.standard`
    font-size: 1.3em;
    font-size: 2vw;
    margin-left: -25%;
  `};

  ${respond.pc.standard`
    font-size: 1.2em;
    font-size: 0.8vw;
    margin-left: -30%;
  `};
`;

export const TriangleSpinner = styled.div`
  display: inline-block;

  width: 6vw;
  height: 6vw;

  ${respond.tablet.standard`
   width: 4vw;
  height: 4vw;
  `}
  ${respond.pc.standard`
   width: 2vw;
  height: 2vw;
  `}
  box-sizing: content-box;
  border-color: blue;
  background-color: ${colors.accent.lightest};
  background: linear-gradient(
    100deg,
    ${colors.accent.darkest} 0%,
    ${colors.accent.lightest} 35%
  );

  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  animation: spin 2s ease-in-out infinite;
  -webkit-animation: spin 2s ease-in-out infinite;

  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
    }
  }
`;